import React, { useEffect, useState, useRef } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { apiCall } from "../../api";
import SectionTopBar from "../../components/SectionTopBar";
import Table from "../../components/Table";
// import SideBar from "../../components/SideBar";
import { PageHeader } from "../../components/Typography";
import SearchBar from "../../components/SearchBar";
import TabularLoadingPlaceholder from "../../components/LoadingPlaceholders/TabularLoadingPlaceholder";
import useFetch from "../../hooks/useFetch";
import { dateFormatter, rolesSelectorBasedOnHierarchy } from "../../utils";
import { APP_USER_ROLES } from "../../constants/Constants";
import useValidation from "../../hooks/useValidate";
import BottomActionBar from "../../components/BottomActionBar";
import usePostRequest from "../../hooks/usePostRequest";

export default function AccessControl() {
  const currentUser = useSelector((state) => state.user.value);
  const tokens = useSelector((state) => state?.tokens?.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const isSaveDisabled = useRef(true);

  const { data, loading } = useFetch(
    `/v1/apps/${currentApp.id}/users`,
    tokens,
    [],
    []
  );
  const [appUsers, setAppUsers] = useState([]);
  const [disabledSave, setDisabledSave] = useState(true);
  const { validate, clearErrors: clearValidationErrors } = useValidation();
  const { sendPostRequest, loading: updatingUsers } = usePostRequest(
    `/v1/apps/${currentApp?.id}/users/upsert`,
    tokens
  );

  useEffect(() => {
    if (data) {
      let newData = data.map((el) => ({ ...el, role: el.app_user?.role }));
      setAppUsers(newData);
    }
  }, [data]);

  const ROLES_ACCESS = rolesSelectorBasedOnHierarchy(
    currentUser.user_type,
    APP_USER_ROLES
  );

  const handleChange = (index, key, value) => {
    isSaveDisabled.current = false;

    setAppUsers((prev) =>
      prev.map((row, i) =>
        i === index
          ? key === "user_type"
            ? { ...row, role: value }
            : { ...row, [key]: value }
          : row
      )
    );
  };

  const handleAddUserClick = () => {
    setAppUsers((prev) => {
      let newContent = [
        {
          email: "",
          user_type: "user",
          role: "client_user",
          name: "",
          created_at: "-",
        },
        ...prev,
      ];
      return newContent;
    });
  };

  const handleSave = async () => {
    clearValidationErrors(appUsers);

    const updatedUsers = [...appUsers];

    for (let index = 0; index < updatedUsers.length; index++) {
      const isValid = validate({
        email: updatedUsers[index].email,
        name: updatedUsers[index].name,
        role: updatedUsers[index].app_user?.role || updatedUsers[index].role,
      });
      if (!isValid) {
        updatedUsers[index] = { ...updatedUsers[index], error: true };
        setAppUsers(updatedUsers);
      }
    }
    if (updatedUsers.some((el) => el.error)) return;

    const response = await sendPostRequest({ users: appUsers });
    isSaveDisabled.current = true;
    setAppUsers(response);
  };

  useEffect(() => {
    if (isSaveDisabled.current) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }
  }, [appUsers, isSaveDisabled]);

  if (loading || updatingUsers) {
    return <TabularLoadingPlaceholder numRows={3} numCols={4} />;
  }

  return (
    <>
      <PageHeader>Access Control</PageHeader>
      <SectionTopBar
        style={{ justifyContent: "space-between", marginTop: "30px" }}
      >
        {/* <SideBar>This is the sidebar</SideBar> */}

        <SearchBar />
        <Button variant="PrimaryButtonBlue" onClick={handleAddUserClick}>
          + Add User
        </Button>
      </SectionTopBar>

      <Table
        customTableStyle={{
          width: "100%",
          marginTop: "30px",
        }}
        customStyle={{
          marginRight: "40px",
          overflowX: "visible",
        }}
        emptyText="Add URLs that your bot will refer to as its knowledge base"
        tableContents={appUsers.map((user, userIndex) => {
          return [
            {
              id: (user.id ? user.id : "new") + "-email",
              element: {
                type: "textfield",
                value: user.email,
                onChange: (e) =>
                  handleChange(userIndex, "email", e.target.value),
                label: "user-email",
                placeholder: "Enter User Email",
                error: user.error,
              },
            },
            {
              id: (user.id ? user.id : "new") + "-name",
              element: {
                type: "textfield",
                value: user.name,
                onChange: (e) =>
                  handleChange(userIndex, "name", e.target.value),
                label: "user-name",
                placeholder: "Enter User Name",
                error: user.error,
              },
            },
            {
              id: (user.id ? user.id : "new") + "-user_type",
              element: {
                type: "selector",
                items: ROLES_ACCESS,
                value: user.role || "client_user",
                setValue: (val) => {
                  handleChange(userIndex, "role", val);
                },
                error: user.error,
              },
            },
            {
              element: {
                value:
                  user.created_at === "-"
                    ? "-"
                    : dateFormatter(user.created_at, "MM/DD/YYYY | HH:mm AMPM"),
              },
            },
          ];
        })}
        rowHeaders={[
          {
            columnData: "Email",
            id: "Email",
          },
          {
            columnData: "Name",
            id: "Name",
          },
          {
            columnData: "Role",
            id: "Role",
          },
          {
            columnData: "Onboarded On",
            id: "Onboarded-on",
          },
        ]}
      />
      <BottomActionBar
        disabledSave={disabledSave}
        handleSave={() => {
          handleSave();
        }}
      />
    </>
  );
}
