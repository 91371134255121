import React, { useEffect, memo, useRef } from "react";
import "./Appearance.css";

const ChatAppearance = ({ market , mapFormStateToApi, formState, mergedFieldsConfig}) => {
  const marketPart = market.split("_")[1];
  const iframeWrapperRef = useRef(null); // Ref for iframe A
  console.log("Rendering ChatAppearance");
  console.log("marketPart :", marketPart);
  
  
  useEffect(() => {
    console.log("calling user effcet of window with ",formState);
    if(formState) 
      reInitScript();
  }, [formState]);

  const reInitScript = () => {
    
    initScript();
  };


  const initScript = () => {
    const iframeWrapper = iframeWrapperRef.current;
    if(iframeWrapper){

    
      const topSpacing=64,bottomSpacing=100;
      iframeWrapper.style.height = `${window.innerHeight-bottomSpacing-topSpacing-40}px`;
   
      iframeWrapper.src = 'about:blank'; // Ensure the iframe triggers the onload event

      iframeWrapper.onload = () => {
        const iframeWrapperDoc = iframeWrapper?.contentDocument || iframeWrapper?.contentWindow?.document;
        iframeWrapper.contentWindow.formState = formState;
        iframeWrapper.contentWindow.mergedFieldsConfig = mergedFieldsConfig;

        // ADDING IFRAME STARTS
        const ifrm = document.createElement("iframe");
        ifrm.setAttribute(
          "src",
          "https://dbrd449anfbv4.cloudfront.net/insync_iframe_webchat_js_dev.html"
        );
        ifrm.setAttribute("id", "insync-iframe");
        ifrm.style.width = 0;
        ifrm.style.height = 0;
        ifrm.style.background = "transparent";
        ifrm.style.position = "fixed";
        ifrm.style.margin = 0;
        ifrm.style.right = 0;
        ifrm.style.bottom = 100;
        ifrm.style.border = "none";
        ifrm.style.overflow = "hidden";
        ifrm.style.display = "block";
        ifrm.style.zIndex = 999999;
        ifrm.style.bordeRradius = 0;
        ifrm.style.boxShadow = "none";

        iframeWrapperDoc.body.appendChild(ifrm);
        // ADDING IFRAME ENDS


        // Adding Webchat JS Starts
        const updatedStyle = mapFormStateToApi(formState, mergedFieldsConfig);

        const insyncChatbotConfig = {
          insyncMarket: market,
          insyncEnv: "development",
          insyncAppIdDev: "702c1e15c41a13c70bd2b181f6a98cc1",
          insyncSource: "backoffice_chat_setup_ui",
          insyncChatIconId: "insync-chat-icon-id",
          insyncIframeId: "insync-iframe",
          insyncAutoOpenTimerInSecs: 1,
          insyncContextParams: {
            modifiedClientStyleGuide: updatedStyle
          }
        };

        
        const insyncWebchatScript = document.createElement("script");
        const insyncWebchatJsSrc =
          "https://dbrd449anfbv4.cloudfront.net/insync_webchat_" +
          insyncChatbotConfig.insyncEnv +
          ".js";
        insyncWebchatScript.setAttribute("id", "insync-webchat");
        insyncWebchatScript.setAttribute("src", insyncWebchatJsSrc);
        insyncWebchatScript.onload = function () {
          if(typeof  iframeWrapperDoc.defaultView.insyncSetChatEnv != "undefined")iframeWrapperDoc.defaultView.insyncSetChatEnv(insyncChatbotConfig);
        };

        iframeWrapperDoc.head.appendChild(insyncWebchatScript);
        // Adding Webchat JS Ends

        // Adding the CSS Starts
        const linkElement = iframeWrapperDoc.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.href = 'https://dbrd449anfbv4.cloudfront.net/insync_chat_button.css';
        iframeWrapperDoc.head.appendChild(linkElement);
        // Adding the CSS Ends

        // Adding the chat icon Starts
        const spanElement = iframeWrapperDoc.createElement('span');
        spanElement.id = 'insync-chat-icon-id';
        spanElement.style.display = "none";
        spanElement.onclick = () => {
          if (iframeWrapperDoc.defaultView && typeof iframeWrapperDoc.defaultView.triggerChat === 'function') {
            iframeWrapperDoc.defaultView.triggerChat();
          } else {
            console.warn('triggerChat function is not defined');
          }
        };
        
        const imgElement = iframeWrapperDoc.createElement('img');
        imgElement.src = 'https://d2c0xhqyhmtkod.cloudfront.net/sdk-assets/draft/'+marketPart+'_chat_button_icon.png';
        imgElement.height = 75;
        imgElement.alt = '';
        spanElement.appendChild(imgElement);

        iframeWrapperDoc.body.appendChild(spanElement);
        // Adding the chat icon Ends

        // Adding the triggerChat function
        const scriptTagTriggerChat = iframeWrapperDoc.createElement('script');
        scriptTagTriggerChat.type = 'text/javascript';
        scriptTagTriggerChat.text = `
          var timeoutRefForIframeStyles;
          if(timeoutRefForIframeStyles)clearTimeout(timeoutRefForIframeStyles);
           var timeoutRefForIconStyles;
          if(timeoutRefForIconStyles)clearTimeout(timeoutRefForIconStyles);
          window.triggerChat = () => {
            console.log("in trigger")
            if(typeof insyncSetChatEnv != "undefined")insyncSetChatEnv.openChat("OpenChat");
          };

          window.getStyles = (key) => {
              console.log(window.formState)
              let styles = {};
              if(window.mergedFieldsConfig[key]){
                styles = Object.fromEntries(
                  mergedFieldsConfig[key]
                  .filter(({ name }) => name && window.formState[name] !== null)
                  .map(({ style, name, unit }) => [
                    style,
                    ["auto", "none", "transparent", null].includes(window.formState[name]) ? window.formState[name] : window.formState[name] + (unit || "")
                  ])
                );
                console.log("styles: ", key, styles)

              }
              return styles;
            }

            window.applyStyles = (element, styleKey) => {
              let styles = window.getStyles(styleKey);
              
              // Loop through the styles object and apply styles to the element
              Object.entries(styles).forEach(([key, value]) => {
                // Convert kebab-case to camelCase
                const camelCaseKey = key.replace(/-([a-z])/g, (_, char) => char.toUpperCase());
                element.style[camelCaseKey] = value;
              });
                
            }

            window.applyStylesIfChatWindowIsOpen = () => {
              const iframe = document.getElementById("insync-iframe");
              if (iframe && iframe.offsetHeight > 0) {
                window.applyStyles(iframe, "Chat Window");
              }else{
                timeoutRefForIframeStyles = setTimeout(() => {
                  window.applyStylesIfChatWindowIsOpen();
                },2000);
              }
            }

            window.applyStyleOnChatIcon = () => {
              const icon = document.getElementById("insync-chat-icon-id");
              if(icon){
                const style = window.getComputedStyle(icon);
                if (style.display === 'block') {
                  window.applyStyles(icon, "Chat Icon Style");
                }else{
                  timeoutRefForIconStyles = setTimeout(() => {
                    window.applyStyleOnChatIcon();
                  },2000);
                }
              }
              
            }
            
            window.applyStyleOnChatIcon();
            window.applyStylesIfChatWindowIsOpen();
        `;
        iframeWrapperDoc.body.appendChild(scriptTagTriggerChat);
      }
    }
   


      // applyStyleOnChatIcon();
    // Manually adding the iframe styles [iFrame webchat JS will take care of it in realtime once pusblished]
    // applyStylesIfChatWindowIsOpen();

  };  

  return (

    <div>
      <iframe
        ref={iframeWrapperRef}
        title="iframeWrapper"
        style={{ width: '600px', minHeight: '590px', border: '1px solid #ccc', position: 'fixed', right: 20, bottom: 100, backgroundColor: "#eeeeee" }}
      >
      </iframe>
    </div>
  );
};

export default memo(ChatAppearance);
