import { createTheme } from "@mui/material/styles";
import { COLORS } from "./constants/Colors";
import {
  GENERIC_BUTTON_FONT_SIZE,
  GENERIC_BUTTON_HEIGHT,
  GENERIC_EDIT_PLACEHORDER_FONT_SIZE,
  GENERIC_LABEL_L_FONT_SIZE,
  GENERIC_LABELM_FONT_SIZE,
} from "./constants/Constants";

const theme = createTheme({
  typography: {
    fontFamily: "THICCCBOI",
  },

  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "PrimaryButton" },
          style: {
            // height: GENERIC_BUTTON_HEIGHT,
            maxHeight: GENERIC_BUTTON_HEIGHT,
            padding: "20px 24px 20px 24px",
            gap: "6px",
            borderRadius: "8px",
            opacity: "0px",
            border: `1px solid ${COLORS.PRIMARY_COLOR_2}`,
            backgroundColor: COLORS.PRIMARY_COLOR_2,
            color: "#FFF",
            fontSize: GENERIC_BUTTON_FONT_SIZE,
            fontWeight: 700,
            textTransform: "none",
            ":hover": {
              backgroundColor: "#E66A00",
            },
            ":disabled": {
              backgroundColor: "#FF760080",
              border: `1px solid #FF760080`,
              color: "#FFF",
              cursor: "none",
            },
          },
        },
        {
          props: { variant: "PrimaryButtonBlue" },
          style: {
            // height: GENERIC_BUTTON_HEIGHT,
            maxHeight: GENERIC_BUTTON_HEIGHT,
            padding: "20px 24px 20px 24px",
            gap: "6px",
            borderRadius: "8px",
            opacity: "0px",
            backgroundColor: COLORS.PRIMARY_COLOR_3,
            border: `1px solid ${COLORS.PRIMARY_COLOR_3}`,
            color: "#FFF",
            fontSize: GENERIC_BUTTON_FONT_SIZE,
            fontWeight: 700,
            textTransform: "none",
            ":hover": {
              backgroundColor: "#2966C7",
            },
            ":disabled": {
              backgroundColor: "#327DF661",
              border: `1px solid #327DF661`,
              color: "#FFF",
              cursor: "none",
            },
          },
        },
        {
          props: { variant: "SecondaryButton" },
          style: {
            // height: GENERIC_BUTTON_HEIGHT,
            maxHeight: GENERIC_BUTTON_HEIGHT,
            padding: "20px 24px 20px 24px",
            gap: "6px",
            borderRadius: "8px",
            opacity: "0px",
            backgroundColor: "#FFF",
            border: "1px solid #DCDEE2",
            fontSize: GENERIC_BUTTON_FONT_SIZE,
            fontWeight: 700,
            textTransform: "none",
          },
        },
        {
          props: { variant: "LightBlueBGButton" },
          style: {
            // height: GENERIC_BUTTON_HEIGHT,
            maxHeight: GENERIC_BUTTON_HEIGHT,
            padding: "20px 24px 20px 24px",
            gap: "6px",
            borderRadius: "8px",
            opacity: "0px",
            background: "#F3F7FF",
            color: "#327DF6",
            border: "1px solid #327DF6",
            fontSize: GENERIC_BUTTON_FONT_SIZE,
            fontWeight: 400,
            textTransform: "none",
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          autoComplete: "off",
        },
      },
      styleOverrides: {
        root: {
          marginRight: "8px",
          marginTop: "0px",
          // fontSize: "13px",
          lineHeight: "18.2px",
          width: "674px",
          gap: "10px",
          opacity: "0px",
          "&::placeholder": {
            color: "#9DA6B6",
          },
          "&.Mui-focusVisible": {
            background: "transparent",
          },
          "& input": {
            // height: "20px !important",
            //           font-size: var(--GENERIC_EDIT_FIELD_FONT_SIZE);
            // width: 100%;
            // border: 1px solid #ccc;
            // border-radius: 4px;
            // outline: none;
            // padding: 8px;
            margin: 0,

            padding: "8px !important",
            border: "1px solid #ccc",
            borderRadius: "4px;",
            fontSize: GENERIC_EDIT_PLACEHORDER_FONT_SIZE + " !important",
          },
          "& textarea": {
            padding: "10.5px 20px !important",
            height: "200px !important",
            border: "1px solid #ccc",
            lineHeight: "16px",
            borderRadius: "4px;",
            border: "1px solid #9DA6B6",
            fontSize: GENERIC_EDIT_PLACEHORDER_FONT_SIZE + " !important",
          },
          "& fieldset": {
            display: "none",
          },
          "& .MuiOutlinedInput-root": {
            padding: "0px",
            minHeight: "50px",
          },
          "& .Mui-error": {
            color: "#DC2B2B !important",
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          marginRight: "8px",
          fontSize: "13px",
          lineHeight: "18.2px",
          width: "674px",
          gap: "10px",
          opacity: "0px",
          "& .Mui-error": {
            color: "#DC2B2B !important",
          },
          "&.Mui-focusVisible": {
            background: "transparent",
          },
          "& input": {
            // height: "20px !important",
            //           font-size: var(--GENERIC_EDIT_FIELD_FONT_SIZE);
            // width: 100%;
            // border: 1px solid #ccc;
            // border-radius: 4px;
            // outline: none;
            // padding: 8px;
            margin: 0,

            padding: "8px !important",
            border: "1px solid #ccc",
            borderRadius: "4px;",
            fontSize: GENERIC_EDIT_PLACEHORDER_FONT_SIZE + " !important",
            "&::placeholder": {
              color: "#9DA6B6",
            },
          },
          "& fieldset": {
            display: "none",
          },
          "& .MuiOutlinedInput-root": {
            padding: "0px",
            // height: "50px",
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          marginRight: "8px",
          fontSize: "13px",
          lineHeight: "18.2px",
          width: "674px",
          gap: "10px",
          opacity: "0px",
          "& .Mui-error": {
            color: "#DC2B2B !important",
          },
          "&.Mui-focusVisible": {
            background: "transparent",
          },
          "& input": {
            // height: "20px !important",
            //           font-size: var(--GENERIC_EDIT_FIELD_FONT_SIZE);
            // width: 100%;
            // border: 1px solid #ccc;
            // border-radius: 4px;
            // outline: none;
            // padding: 8px;
            margin: 0,

            padding: "8px !important",
            border: "1px solid #ccc",
            borderRadius: "4px;",
            fontSize: GENERIC_EDIT_PLACEHORDER_FONT_SIZE + " !important",
            "&::placeholder": {
              color: "#9DA6B6",
            },
          },
          "& fieldset": {
            display: "none",
          },
          "& .MuiOutlinedInput-root": {
            padding: "0px",
            // height: "50px",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            fontSize: GENERIC_LABEL_L_FONT_SIZE,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontSize: "20px",
          padding: "10px 13px",
          width: "230px",
          marginBottom: "0px !important",
          fontWeight: "400",
          lineHeight: "18px",
          textAlign: "left",
          color: "#4C5A71",
          "&:hover": {
            backgroundColor: "#F3F7FF !important",
            textDecoration: "underline",
          },
          "&.Mui-selected": {
            backgroundColor: "#F3F7FF",
            "& span": {
              fontWeight: "700 !important",
            },
            "& svg": {
              fontWeight: "700 !important",
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.PRIMARY_COLOR_1,
          "&  .MuiToolbar-root": {
            justifyContent: "space-between",
            // marginLeft: "50px",
            // marginRight: "50px",
          },
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "TableAddTag" },
          style: {
            width: "109px",
            height: "26px",
            fontFamily: "THICCCBOI",
            fontsize: "14px",
            fontweight: "400",
            lineheight: "16.14px",
            textalign: "left",
            background: "transparent",
            borderRadius: "18px 18px 18px 18px",
            padding: "5px, 15px, 5px, 15px",
            border: "1px solid #9DA6B6",
            textTransform: "none",
            color: "#4C5A71",
          },
        },
      ],
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          fontSize: 13,
          "& .MuiTableCell-root": {
            borderBottom: "1px solid #D8DCE2",
            verticalAlign: "middle",
            // maxWidth: "350px",
            paddingTop: "0px",
            paddingBottom: "0px",
            height: "45px",
          },
          "& .MuiTableCell-head": {
            backgroundColor: "#FFF3E4",
            fontFamily: "THICCCBOI",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "16.14px",
            textAlign: "left",
            height: "58px",
            textTransform: "uppercase",
          },
        },
      },
    },
  },
});

export default createTheme(theme);
