import { isEmpty } from "lodash";

export const getSubdomainAndMainDomain = (url) => {
  const cleanUrl = url.replace(/(^\w+:|^)\/\//, "");
  const domain = cleanUrl.startsWith("www.") ? cleanUrl.substring(4) : cleanUrl;

  const parts = domain.split(".");

  if (parts.length > 2) {
    const subdomain = parts.slice(0, -2).join("");
    const mainDomain = parts.slice(-2, -1)[0];
    return `${subdomain}${mainDomain}`;
  }

  return parts[0];
};

export const dateFormatter = (dateString, format) => {
  const date = new Date(dateString);
  const formatParts = {
    DD: String(date.getDate()).padStart(2, "0"),
    MM: String(date.getMonth() + 1).padStart(2, "0"),
    YYYY: date.getFullYear(),
    HH: String(date.getHours() % 12 || 12).padStart(2, "0"),
    mm: String(date.getMinutes()).padStart(2, "0"),
    ss: String(date.getSeconds()).padStart(2, "0"),
    AMPM: date.getHours() >= 12 ? "PM" : "AM",
  };
  return format.replace(
    /DD|MM|YYYY|HH|mm|ss|AMPM/g,
    (match) => formatParts[match]
  );
};

export const rolesSelectorBasedOnHierarchy = (role, rolesArray) => {
  const currentRoleIndex = rolesArray.findIndex((r) => r.value === role);

  return rolesArray.filter((r, index) => index <= currentRoleIndex);
};

export const convertUnderscoreToSpaces = (str) => {
  if (typeof str !== "string" || str === null || str === undefined) {
    return "";
  }

  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getStringParamsFromObject = (params) => {
  return Object.keys(params)
    .filter((key) => typeof params[key] === "number" || !isEmpty(params[key]))
    .map((key) => key + "=" + encodeURIComponent(params[key]))
    .join("&");
};

// converts date string format: 2024-12-18T05:58:29.349Z to format like: Aug 4, 2024 at 07:30pm
export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
};

// Function to get Chip properties based on status
export const getStatusChipProps = (status) => {
  switch (status) {
    case "pending_review":
      return { label: convertUnderscoreToSpaces(status), color: "warning" };
    case "pending_sitemap_generation":
      return { label: convertUnderscoreToSpaces(status), color: "info" };
    case "sitemap_generated":
      return { label: convertUnderscoreToSpaces(status), color: "secondary" };
    case "to_be_indexed":
      return { label: convertUnderscoreToSpaces(status), color: "warning" };
    case "sent_for_indexing":
      return { label: convertUnderscoreToSpaces(status), color: "info" };
    case "indexed":
      return { label: convertUnderscoreToSpaces(status), color: "success" };
    case "to_be_skipped":
      return { label: convertUnderscoreToSpaces(status), color: "default" };
    case "sent_for_skipping":
      return { label: convertUnderscoreToSpaces(status), color: "info" };
    case "skipped":
      return { label: convertUnderscoreToSpaces(status), color: "default" };
    case "to_be_deleted":
      return { label: convertUnderscoreToSpaces(status), color: "info" };
    case "sent_for_deletion":
      return { label: convertUnderscoreToSpaces(status), color: "info" };
    case "deleted":
      return { label: convertUnderscoreToSpaces(status), color: "error" };
    case "failed_to_index":
      return { label: convertUnderscoreToSpaces(status), color: "error" };
    case "failed_to_delete":
      return { label: convertUnderscoreToSpaces(status), color: "error" };
    case "failed_to_skip":
      return { label: convertUnderscoreToSpaces(status), color: "error" };
    default:
      return { label: convertUnderscoreToSpaces(status), color: "default" };
  }
};

// Removes duplicates from an array
export const removeDuplicatesFromArray = (arr) => {
  return arr.reduce((acc, item) => {
    if (!acc.includes(item)) {
      acc.push(item);
    }
    return acc;
  }, []);
};

export const generateTempId = () => `temp-${Date.now()}-${Math.random()}`;

export const yearsListSince = (date) => {
  const currentYear = new Date().getFullYear();
  const startYear = new Date(date).getFullYear();
  const years = [];
  for (let i = startYear; i <= currentYear; i++) {
    years.push(i.toString(10));
  }
  return years;
}
