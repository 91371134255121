import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import copyToClipboard from "../utils/copyToClipboard";

const CodeSnippetViewer = ({ code, label, language }) => {
  return (
    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        {label}
      </Typography>
      <Box
        sx={{
          borderRadius: 1,
          overflow: "auto",
          maxHeight: "700px",
          maxWidth: "1100px",
        }}
      >
        <SyntaxHighlighter
          language={language}
          style={okaidia}
          customStyle={{
            backgroundColor: "black",
            padding: "20px",
            borderRadius: "5px",
            fontSize: "14px",
          }}
        >
          {code}
        </SyntaxHighlighter>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => copyToClipboard(code)}
        sx={{ marginTop: 1 }}
      >
        Copy Script
      </Button>
    </Box>
  );
};

export default CodeSnippetViewer;
