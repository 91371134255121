import React, { useEffect, useCallback } from "react";
import { InputAdornment, TextField } from "@mui/material";
import useSearch from "../hooks/useSearch";
import { SearchIcon } from "./utils/InsyncIconsUtil";
import { SEARCH_STYLE } from "../constants/Constants";

/**
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.data - The data array used for local filtering.
 * @param {string} [props.url] - The API endpoint used for remote search.
 * @param {Array<string>} [props.searchKeys] - The keys to search within in the data objects.
 * @param {function} [props.onSearchResults] - Callback function to handle filtered results.
 * @param {"onChange"|"onEnter"} [props.triggerOn="onChange"] - Determines if the search should
 * trigger on every input change or when Enter is pressed.
 * @returns {JSX.Element} A `TextField` component with search functionality.
 */

const SearchBar = ({
  data,
  url,
  searchKeys,
  queryParam,
  onChange = () => {},
  onSearchResults = () => {},
  onSearch = () => {},
  triggerOn = "onChange",
  customStyle = {},
}) => {
  const { query, setQuery, filteredData } = useSearch({
    data,
    url,
    searchKeys,
    queryParam,
    triggerOn,
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    onChange(value);
    if (triggerOn === "onChange") {
      onSearch(value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && triggerOn === "onEnter") {
      // Trigger search on Enter
      onSearch(query);
    }
  };

  const handleBlur = () => {
    if (triggerOn === "onEnter") {
      onSearch(query);
    }
  };

  // Use useCallback to memoize the onSearchResults function
  const handleSearchResults = useCallback(() => {
    onSearchResults(filteredData);
  }, [filteredData]);

  useEffect(() => {
    handleSearchResults();
  }, [handleSearchResults]);

  return (
    <TextField
      placeholder="Search"
      margin="dense"
      sx={{ ...SEARCH_STYLE, ...customStyle }}
      value={query}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={handleInputChange}
      onKeyDown={handleKeyPress}
      onBlur={handleBlur}
    />
  );
};

export default SearchBar;
