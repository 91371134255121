import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, CircularProgress } from "@mui/material";
import usePostRequest from "../../hooks/usePostRequest";
import { toast } from "react-toastify"

const FileUpload = ({ field, onChange, accept = "", endpoint, imageLabelStyleVariant="h6" , buttonVariant="outlined"}) => {
  const tokens = useSelector((state) => state?.tokens.value);
  const fileInputRef = useRef();

  const [isImageValid, setIsImageValid] = useState(false);

  const { loading: uploading, sendPostRequest } = usePostRequest(
    endpoint,
    tokens
  );

  useEffect(() => {
    if (field?.defaultValue) {
      // Check if the image URL is valid by trying to load it
      const img = new Image();
      img.src = field.defaultValue;

      img.onload = () => setIsImageValid(true); // Image loaded successfully
      img.onerror = () => setIsImageValid(false); // Image failed to load
    } else {
      setIsImageValid(false); // No URL provided
    }
  }, [field.defaultValue]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    const formData = new FormData();
    files.forEach((file) => {
      if(field.file_name){
        formData.append("files[]", file, field.file_name);
      }else{
        formData.append("files[]", file);
      }
      
    });

    try {
      const response = await sendPostRequest(formData);

      const uploadedFileUrls = response.files.map((file) => file.url);
      //console.log(uploadedFileUrls)
      // Update the form state immediately
      onChange({
        target: {
          name: field.name,
          value: uploadedFileUrls,
        },
      });
    } catch (error) {
      console.error("Failed to upload files", error);
      //console.log(error)
      //alert("Failed to upload file. Please try again.");
      toast.error(error.message)
    }
  };

  return (
    <div className="form-field">
      <Typography variant={imageLabelStyleVariant}>{field.label}</Typography>
      <input
        type="file"
        multiple={field.multiple}
        accept={accept}
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <Button
        variant={buttonVariant}
        className="upload-button"
        onClick={handleButtonClick}
        disabled={uploading}
      >
        {isImageValid ? (
          <img
            src={field.defaultValue}
            alt="Loaded content"
            height={"36.5px"}
          />
        ) : uploading ? (
          <CircularProgress size={24} />
        ) : (
          field.title || "Upload"
        )}
      </Button>
    </div>
  );
};

export default FileUpload;
