import React, { useState, useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import SectionTopBar from "../../components/SectionTopBar";
import {
  PageHeader,
  PageSubheader,
  SubtitleS,
} from "../../components/Typography";
import useFetch from "../../hooks/useFetch";
import usePutRequest from "../../hooks/usePutRequest";
import useAutoSave from "../../hooks/useAutoSave";
import TextFieldsLoadingPlaceholder from "../../components/LoadingPlaceholders/TextFieldsLoadingPlaceholder";
import { getStringParamsFromObject } from "../../utils";
import usePostRequest from "../../hooks/usePostRequest";
import BottomActionBar from "../../components/BottomActionBar";

export default function UserProbing() {
  // ===============================================
  // INITIALIZERS
  // ===============================================
  const tokens = useSelector((state) => state?.tokens?.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const isSaveDisabled = useRef(true);

  // Fetch initial data using custom useFetch hook
  const {
    data: probes,
    loading,
    error,
  } = useFetch(
    // TODO: Update the fetch endpoint here
    `/v1/apps/${currentApp.id}/ai_probings?${getStringParamsFromObject({
      environment: "staging",
      types:
        "topic_wise_clarifying_questions,sub_topic_recommendations,related_topic_recommendations",
    })}`,
    tokens,
    []
  );

  // Initialize the usePutRequest hook for PUT requests
  // TODO: Update the endpoint to update the probs
  const { sendPutRequest } = usePutRequest(
    `/v1/apps/${currentApp.id}/ai_probings`,
    tokens
  );

  const { sendPostRequest } = usePostRequest(
    `/v1/apps/${currentApp.id}/ai_probings`,
    tokens
  );

  // State to manage the sensitive topics and no-go topics
  const [state, setState] = useState({
    topic_wise_clarifying_questions: {
      probing_prompt: "",
      probing_guidelines: "",
      id: null,
      environment: "",
      app_id: null,
    },
    sub_topic_recommendations: {
      probing_prompt: "",
      probing_guidelines: "",
      id: null,
      environment: "",
      app_id: null,
    },
    related_topic_recommendations: {
      probing_prompt: "",
      probing_guidelines: "",
      id: null,
      environment: "",
      app_id: null,
    },
  });

  const [disabledSave, setDisabledSave] = useState(true);
  const [probsUpdated, setProbsUpdated] = useState({
    topic_wise_clarifying_questions: false,
    sub_topic_recommendations: false,
    related_topic_recommendations: false,
  });

  // Calculate the number of sections based on the state
  const numSections = Object.keys(state).length;

  // ===============================================
  // HANDLERS
  // ===============================================

  // Function to save configurations
  const saveConfigurations = async () => {
    const updatedProbs = [];

    // Prepare the updated configurations
    Object.keys(probsUpdated).forEach((type) => {
      if (probsUpdated[type]) {
        const config = {
          probing_type: type,
          probing_guidelines: state[type].probing_guidelines,
          environment: "staging",
          app_id: currentApp?.id,
        };
        if (state[type].id) {
          config.id = state[type].id;
        }
        updatedProbs.push(config);
      }
    });

    // Make PUT request if there are updated configurations
    if (updatedProbs.length > 0) {
      try {
        let response = {};

        if (updatedProbs[0].id) {
          response = await sendPutRequest(
            {
              ...updatedProbs[0],
            },
            `/${updatedProbs[0].id}`
          );
        } else {
          response = await sendPostRequest({
            ...updatedProbs[0],
          });
        }

        // Update the local state with the response
        const updatedConfigs = response;

        if (!isEmpty(updatedConfigs)) {
          isSaveDisabled.current = true;
          setState((prevState) => ({
            ...prevState,
            [updatedConfigs.probing_type]: {
              probing_type: updatedConfigs.control_definition,
              probing_guidelines: updatedConfigs.disclaimer,
              probing_prompt: updatedConfigs.probing_prompt,
              id: updatedConfigs.id,
              environment: updatedConfigs.environment,
              app_id: updatedConfigs.app_id,
            },
          }));
        }

        setProbsUpdated({
          topic_wise_clarifying_questions: false,
          sub_topic_recommendations: false,
          related_topic_recommendations: false,
        });
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };

  // Handle changes in the form fields
  const changeHandler = (event, type) => {
    isSaveDisabled.current = false;
    const { name, value } = event.target;

    // Update local state with new values
    setState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [name]: value,
      },
    }));

    setProbsUpdated((prevState) => ({
      ...prevState,
      [type]: true,
    }));
  };

  // ===============================================
  // EFFECTS
  // ===============================================

  // Use effect to set initial configurations when they are fetched
  useEffect(() => {
    if (probes.length > 0) {
      isSaveDisabled.current = true;
      probes.forEach((config) => {
        setState((prevState) => ({
          ...prevState,
          [config.probing_type]: {
            probing_prompt: config.probing_prompt,
            probing_guidelines: config.probing_guidelines,
            id: config.id,
            environment: config.environment,
            app_id: config.app_id,
          },
        }));
      });
    }
  }, [probes]);

  // Use effect to enable save buttton when state changes
  useEffect(() => {
    if (isSaveDisabled.current) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }
  }, [state]);

  // ===============================================
  // RENDER FUNCTIONS
  // ===============================================

  // Render a text field with the given parameters
  const renderTextField = (type, label, placeholder, name, subText, rows) => (
    <>
      <SubtitleS
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          color: "#000",
          maxWidth: "674px",
          textAlign: "left",
        }}
      >
        {label}
      </SubtitleS>
      <TextField
        type="text"
        margin="dense"
        name={name}
        value={state[type][name]}
        onChange={(e) => changeHandler(e, type)}
        sx={{
          marginBottom: "24px",
          "& .MuiOutlinedInput-root": {},
          "& .MuiInputBase-input": {
            padding: "25px 20px 25px 20px",
          },
        }}
        multiline
        minRows={rows}
        maxRows={rows}
        placeholder={placeholder}
        aria-label={name}
      />
    </>
  );

  // Display loading state while fetching data
  if (loading) {
    return <TextFieldsLoadingPlaceholder numSections={numSections} />;
  }

  // Display error message if there was an error
  if (error) return <p>Error: {error.message}</p>;

  // ===============================================
  // MAIN RENDER
  // ===============================================
  return (
    <>
      <PageHeader>User Probing Guidelines</PageHeader>

      {/* =================================================
      // User Probing Guidelines SECTION
      ================================================= */}
      <PageSubheader>Topic Wise Clarifying Questions</PageSubheader>
      <SectionTopBar
        style={{ justifyContent: "space-between", paddingRight: "8px" }}
      />

      {renderTextField(
        "topic_wise_clarifying_questions",
        "AI chatbot can ask clarifying questions to the users/students on defined dimensions of a topic to get more ‘value’ input based on which answer can be improved. You can define these topics and their respective dimensions as well as the required values as shown in the examples.",
        "Topic | Dimension | Values\nPrograms | Program Types: Undergraduate Degree/ Graduate Degree/ Graduate Certificates/ Nondegree Programs\nPrograms | Area of Interest | Business/ Education/ Engineering/ Health and nursing/ Humanities and arts/ Law and public service/ Science/ Social and behavioral sciences/ Technology\nPrograms | Student Types | (First-Year Student/ Transfer Student/ International  Student/ Readmission Student)\nAdmissions | Program Types |  Undergraduate Degree/ Graduate Degree/ Graduate Certificates/ Nondegree Programs\nAdmissions | Student Types | First-Year Student/ Transfer Student/ International  Student/ Readmission Student/ Homeschool\nAdmissions | Benefits | Military or Military dependent/ Corporate",
        "probing_guidelines",
        "",
        10
      )}

      {/* =================================================
      // Recommend Related Topics/Sub-Topics SECTION
      ================================================= */}
      <PageSubheader>Recommend Related Sub-Topics</PageSubheader>
      <SectionTopBar
        style={{ justifyContent: "space-between", paddingRight: "8px" }}
      />

      {renderTextField(
        "sub_topic_recommendations",
        "AI chatbot can drive the conversation with students further by suggesting certain defined sub-topics based on the topic of their current query and conversation. This could be based on commonly asked sub-topics of a topics for example, tuition fees while enquiring about a program. You an define these topics and their respective sub-topics below as shown in the examples.",
        "Topic | Sub Topics\nTransfer | (Max Transferable Credits) (Min GPA Requirement) (Transfer Process) (Transcript Requirements) (Earned Admission)\nPrograms | (Courses Covered) (How to Apply) (Eligibility Criteria) (Tuition and Fees)",
        "probing_guidelines",
        "",
        10
      )}

      {/* =================================================
      // Recommend Related Topics SECTION
      ================================================= */}
      <PageSubheader>Recommend Related Topics</PageSubheader>
      <SectionTopBar
        style={{ justifyContent: "space-between", paddingRight: "8px" }}
      />

      {renderTextField(
        "related_topic_recommendations",
        "Similar to the sub-topics, AI chatbot can drive the conversation with students by suggesting defined topics based on the current topic of the query. For example, you could define ‘Campus Visit’ as a topic when a student is asking questions on the ‘Admissions’ topic or the ‘Campus’ topic. You an define these topics and their respective related topics below as shown in the examples.",
        "Topic | Sub Topics\nTransfer | (Financial Aid) (Tuition Fee)\nPrograms | (Admissions) (Finaicial Aid)",
        "probing_guidelines",
        "",
        10
      )}
      <BottomActionBar
        disabledSave={disabledSave}
        handleSave={() => {
          saveConfigurations();
        }}
      />
    </>
  );
}
