import React from "react";
import ContentLoader from "react-content-loader";

/**
 * Loading placeholder for the Appearance component.
 *
 * @param {number} sections - Number of sections to render.
 * @returns {JSX.Element} The loading placeholder component.
 */
const AppearanceLoadingPlaceholder = ({ sections = 2 }) => {
  const sectionTitleHeight = 30;
  const sectionSpacing = 40;
  const subtitleHeight = 25;
  const subtitleSpacing = 20;
  const fieldHeight = 20;
  const fieldSpacing = 30;
  const fieldWidth = "45%";

  const generateSections = () => {
    let yOffset = 20; // Initial offset from the top

    const sectionsArray = Array.from({ length: sections }).map(
      (_, sectionIndex) => {
        const sectionElements = (
          <React.Fragment key={`section-${sectionIndex}`}>
            {/* Section Title */}
            <rect
              x="20"
              y={yOffset}
              rx="4"
              ry="4"
              width="300"
              height={sectionTitleHeight}
            />
            {(yOffset += sectionTitleHeight + sectionSpacing)}

            {/* Subtitle 1 */}
            <rect
              x="20"
              y={yOffset}
              rx="4"
              ry="4"
              width="200"
              height={subtitleHeight}
            />
            {(yOffset += subtitleHeight + subtitleSpacing)}

            {/* Fields under Subtitle 1 */}
            {[...Array(3).keys()].map((fieldIndex) => (
              <React.Fragment
                key={`section-${sectionIndex}-subtitle1-field-${fieldIndex}`}
              >
                <rect
                  x="20"
                  y={yOffset}
                  rx="4"
                  ry="4"
                  width={fieldWidth}
                  height={fieldHeight}
                />
                <rect
                  x={`calc(100% - 20% - ${fieldWidth})`}
                  y={yOffset}
                  rx="4"
                  ry="4"
                  width={fieldWidth}
                  height={fieldHeight}
                />
                {(yOffset += fieldHeight + fieldSpacing)}
              </React.Fragment>
            ))}

            {/* Subtitle 2 */}
            <rect
              x="20"
              y={yOffset}
              rx="4"
              ry="4"
              width="200"
              height={subtitleHeight}
            />
            {(yOffset += subtitleHeight + subtitleSpacing)}

            {/* Fields under Subtitle 2 */}
            {[...Array(3).keys()].map((fieldIndex) => (
              <React.Fragment
                key={`section-${sectionIndex}-subtitle2-field-${fieldIndex}`}
              >
                <rect
                  x="20"
                  y={yOffset}
                  rx="4"
                  ry="4"
                  width={fieldWidth}
                  height={fieldHeight}
                />
                <rect
                  x={`calc(100% - 20% - ${fieldWidth})`}
                  y={yOffset}
                  rx="4"
                  ry="4"
                  width={fieldWidth}
                  height={fieldHeight}
                />
                {(yOffset += fieldHeight + fieldSpacing)}
              </React.Fragment>
            ))}
          </React.Fragment>
        );

        yOffset += sectionSpacing * 2; // Additional spacing before the next section
        return sectionElements;
      }
    );

    return sectionsArray;
  };

  return (
    <ContentLoader
      speed={2}
      style={{
        marginLeft: "-20px",
      }}
      viewBox="0 0 1200 1500"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      height="100%"
      width="100%"
    >
      {generateSections()}
    </ContentLoader>
  );
};

export default AppearanceLoadingPlaceholder;
