import React from "react";
import { LabelM } from "./Typography";

const CustomTextField = ({
  type="text",
  label,
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  fullBorder,
  className,
}) => {
  const formFieldClassName = className ? className : "form-field";

  return (
    <div className={formFieldClassName}>
      {label && (
        <LabelM style={{ fontSize: "1rem", textAlign: "left" }}>{label}</LabelM>
      )}
      <div className="field-control">
        <input
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          placeholder={placeholder}
          className={fullBorder ? "full-border-field" : "text-field"}
        />
      </div>
    </div>
  );
};

export default CustomTextField;
