import { Checkbox, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { OptionsMenuIcon } from "./utils/InsyncIconsUtil";
import { SmallHeader } from "./Typography";

export default function OptionMenu({
  options,
  button = null,
  zIndex = 21474833, // increase this value if using inside sidebar
  enableCheckBoxes = false,
  title = null,
  customStyle = {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clonedButton = button ? (
    React.cloneElement(button, { onClick: handleClick })
  ) : (
    <IconButton
      aria-label="more"
      id="long-button"
      aria-controls={open ? "long-menu" : undefined}
      aria-expanded={open ? "true" : undefined}
      aria-haspopup="true"
      onClick={handleClick}
    >
      <OptionsMenuIcon />
    </IconButton>
  );

  const handleMenuItemClick = (index, option) => {
    // Close the menu before calling the onClick handler
    handleClose();

    if (typeof option.onClick === "function") {
      option.onClick();
    }
  };

  return (
    <div>
      {clonedButton}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: zIndex,
        }}
        PaperProps={{
          style: {
            width: "20ch",
            zIndex: zIndex,
            ...customStyle,
          },
        }}
      >
        {title && (
          <SmallHeader style={{ margin: "4px 10px" }}>{title}</SmallHeader>
        )}
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            onClick={() => handleMenuItemClick(index, option)}
          >
            {enableCheckBoxes && (
              <Checkbox checked={option.selected || false} />
            )}{" "}
            <span>{option.title}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
