import { Button, Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function BottomActionBar({
  name = "Save",
  handleSave,
  handleCancel,
  cancelRedirectUrl = "",
  disabledSave = false,
  buttons
}) {
  let cancelButtonProps = {
    variant: "SecondaryButton",
    sx: { marginRight: "8px" },
  };

  if (handleCancel !== undefined) {
    cancelButtonProps["onClick"] = () => handleCancel();
  }
  if (cancelRedirectUrl !== "") {
    cancelButtonProps["LinkComponent"] = Link;
    cancelButtonProps["to"] = cancelRedirectUrl;
  }

  return (
    <footer className="bottom-footer">
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "10px",
          marginTop: 1,
        }}
      >
        {(cancelRedirectUrl !== "" || handleCancel !== undefined) && (
          <Button {...cancelButtonProps}>Cancel</Button>
        )}
        {buttons !== undefined && buttons.map((button) => (
          <Button
            key={button.name}
            disabled={button.disabledSave}
            variant="PrimaryButton"
            onClick={() => {
              button.onClick("production");
            }}
            sx={{
              marginRight: "15px",
            }}
          >
            {button.name}
          </Button>
        ))}
        <Button
          disabled={disabledSave}
          variant="PrimaryButton"
          onClick={() => {
            handleSave();
          }}
        >
          {name}
        </Button>
      </Stack>
    </footer>
  );
}
