import { Button, Grid, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { selectApp } from "../../slices/AppsSlice";
import SectionTopBar from "../../components/SectionTopBar";
import {
  CaptionM,
  LabelM,
  PageHeader,
  SubtitleM,
  SubtitleS,
} from "../../components/Typography";
import { ActiveIcon } from "../../components/utils/InsyncIconsUtil";
import {
  FILES_CLOUDFRONT_BASE_URL,
  PLACEHOLDER_LOGO_URL_S3,
  SEARCH_STYLE,
} from "../../constants/Constants";

export default function Clients(props) {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const apps = useSelector((state) => state?.apps.list || []);

  const [search, setSearch] = useState("");
  const [searchedApps, setSearchedApps] = useState([]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleAppClick = (app) => {
    dispatch(selectApp(app));
    navigate(`/${app.url_code}/`);
  };

  useEffect(() => {
    if (search.length > 0) {
      let newSearchedApps = apps.filter(
        (app) =>
          app.client_name.toLowerCase().includes(search.toLowerCase()) ||
          app.url_code?.toLowerCase().includes(search.toLowerCase()) ||
          app.primary_website?.toLowerCase().includes(search.toLowerCase())
      );
      setSearchedApps(newSearchedApps);
    } else {
      setSearchedApps(apps);
    }
  }, [search, apps]);

  return (
    <>
      <SectionTopBar>
        <Stack
          sx={{ width: "100%", margin: "10px" }}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <PageHeader>Clients</PageHeader>
        </Stack>
      </SectionTopBar>
      <div
        key={"search-apps-container"}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 1,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginLeft: "10px",
          width: "50%",
        }}
      >
        <TextField
          key={"search-apps"}
          placeholder="Search"
          sx={SEARCH_STYLE}
          value={search}
          onChange={handleSearchChange}
        />
      </div>
      <Grid
        sx={{
          margin: "10px",
          marginTop: "25px",
          // width: "50%",
          rowGap: "26px",
          columnGap: "26px",
        }}
        container
        // columns={3}
        columnSpacing={1}
        // rowGap={1}
      >
        {searchedApps?.map((app) => (
          <Grid
            item
            component={Button}
            key={app.client_name.id}
            lg={3}
            sx={{
              border: "1px solid #D8DCE2",
              borderRadius: "10px",
              height: "126px",
              justifyContent: "flex-start",
              alignItems: "center",
              textAlign: "center",
              padding: 0,
              minWidth: "364px !important",
              maxWidth: "364px !important",
              width: "364px",
              paddingLeft: "0px !important",
              // verticalAlign: "center",
            }}
            onClick={() => handleAppClick(app)}
          >
            <div className="app-image-container">
              {
                <img
                  className="app-image"
                  src={
                    app.logo_url
                      ? app.logo_url
                      : FILES_CLOUDFRONT_BASE_URL + PLACEHOLDER_LOGO_URL_S3
                  }
                />
              }
            </div>
            <div className="flex-column app-logo-title-container">
              <SubtitleM
                style={{
                  wordBreak: "break-word",
                  color: "#000000",
                  textAlign: "left",
                  maxWidth: "100%",
                  textTransform: "capitalize",
                }}
              >
                {app.client_name}
              </SubtitleM>
              <div className="flex-row flex-justify-content-start flex-align-items-center">
                <ActiveIcon />
                <SubtitleS
                  style={{
                    // color: "#000000",
                    marginLeft: "8px",
                  }}
                >
                  {app.status}
                </SubtitleS>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
