import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { Collapse, Alert, Button, Typography } from "@mui/material";
import DynamicFormField from "../../components/Appearance/DynamicFormField";
import ReactJsonViewCompare from "react-json-view-compare";
import Dialog from "../../components/Dialog.js";
import { isEmpty } from "lodash";
// import useAutoSave from "../../hooks/useAutoSave";
import useFetch from "../../hooks/useFetch";
import usePutRequest from "../../hooks/usePutRequest";
import usePostRequest from "../../hooks/usePostRequest.js";
import {
  SubtitleM,
  PageHeader,
  PageSubheader,
} from "../../components/Typography";
import "./Appearance.css";
import {
  ExpandLessIcon,
  ExpandMoreIcon,
} from "../../components/utils/InsyncIconsUtil";
import AppearanceLoadingPlaceholder from "../../components/LoadingPlaceholders/AppearanceLoadingPlaceholder";
import {
  APPEARANCE_GET_ENDPOINT,
  APPEARANCE_UPDATE_ENDPOINT,
  APPEARANCE_PROD_DIFF_ENDPOINT,
  APPEARANCE_IMAGE_UPLOAD_ENDPOINT
} from "../../constants/Constants";
import BottomActionBar from "../../components/BottomActionBar";
import ChatAppearance from "./ChatAppearance"
import { toast } from "react-toastify";
import {
  Select,
  MenuItem,
  FormControl,
  Box,
} from "@mui/material";

// Utility function to map internal names to form state names
const mapApiToFormState = (apiData, fieldsConfig) => {
  const formState = {};

  Object.keys(fieldsConfig).forEach((section) => {
    fieldsConfig[section].forEach((field) => {
      if (field.internalName) {
        formState[field.name] =
          apiData[field.internalName] ?? field.defaultValue;
      }
    });
  });

  return formState;
};

// Utility function to map form state fields to API payload fields
const mapFormStateToApi = (formState, fieldsConfig) => {
  const apiPayload = {};

  Object.keys(fieldsConfig).forEach((section) => {
    fieldsConfig[section].forEach((field) => {
      if (field.internalName && formState[field.name]) {
        apiPayload[field.internalName] = formState[field.name];
      }
    });
  });
  return apiPayload;
};

const mergeConfigArrays = (baseConfig, additionalConfig) => {
  return Object.entries(additionalConfig).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: [...(acc[key] || []), ...value],
    };
  }, baseConfig);
};

const mergeFieldsConfig = (basicFields, advancedFields, imageAndIconFields) => {
  return [imageAndIconFields, advancedFields].reduce(
    (config, fields) => mergeConfigArrays(config, fields),
    { ...basicFields }
  );
};

const Appearance = () => {
  const tokens = useSelector((state) => state?.tokens?.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  // const [pendingSave, setPendingSave] = useState(false);

  const [formState, setFormState] = useState({});
  const [basicOpen, setBasicOpen] = useState(true);
  const [imagesIconOpen, setImagesIconOpen] = useState(false);
  const [advancedOpen, setAdvancedOpen] = useState(false);

  const [basicSettingsFields, setBasicSettingsFields] = useState({});
  const [advancedSettingsFields, setAdvancedSettingsFields] = useState({});
  const [imageAndIconFields, setImageAndIconsFields] = useState({});
  const [mergedFieldsConfig, setMergedFieldsConfig] = useState({});

  const isSaveDisabled = useRef(true);
  const [disabledSave, setDisabledSave] = useState(true);

  const [open, setOpen] = useState(false);

  const [oldStyleJson, setOldStyleJson] = useState({});
  const [newStyleJson, setNewStyleJson] = useState({});

  const [triggerPointsDetails, setTriggerPointsDetails] = useState({
    type: "select",
    label: "Trigger Points",
    name: "triggerPoints",
    options: currentApp?.trigger_points || [],
    selectedValue: "",
  });

  // Fetch initial data
  const {
    data: responseData,
    loading,
    error,
    fetchData,
  } = useFetch(
    `${APPEARANCE_GET_ENDPOINT}?appId=${currentApp?.id}&triggerPoint=${triggerPointsDetails.selectedValue}`,
    tokens,
    []
  );

  const { sendPutRequest } = usePutRequest(
    `${APPEARANCE_UPDATE_ENDPOINT}?appId=${currentApp?.id}&triggerPoint=${triggerPointsDetails.selectedValue}`,
    tokens
  );

  const { sendPostRequest } = usePostRequest(
    `${APPEARANCE_PROD_DIFF_ENDPOINT}?appId=${currentApp?.id}&triggerPoint=${triggerPointsDetails.selectedValue}`,
    tokens
  );

  // Map fetched data to formState
  useEffect(() => {
    if (!isEmpty(responseData)) {
      const data = responseData?.data;
      setBasicSettingsFields(responseData?.fields_data?.BASIC_SETTINGS_FIELDS);
      setAdvancedSettingsFields(
        responseData?.fields_data?.ADVANCED_SETTINGS_FIELDS
      );
      setImageAndIconsFields(responseData?.fields_data?.IMAGES_AND_ICONS);
      const mergedFieldsConfigLocal = mergeFieldsConfig(
        responseData?.fields_data?.BASIC_SETTINGS_FIELDS,
        responseData?.fields_data?.IMAGES_AND_ICONS,
        responseData?.fields_data?.ADVANCED_SETTINGS_FIELDS
      );
      setMergedFieldsConfig(mergedFieldsConfigLocal);

      const basicFormState = mapApiToFormState(data, mergedFieldsConfigLocal);
      const imagesAndIconsFormState = mapApiToFormState(
        data,
        responseData?.fields_data?.IMAGES_AND_ICONS
      );
      const advancedFormState = mapApiToFormState(
        data,
        responseData?.fields_data?.ADVANCED_SETTINGS_FIELDS
      );
      setFormState({
        ...basicFormState,
        ...imagesAndIconsFormState,
        ...advancedFormState,
      });
    }
    // else {
    //   // If no initial data, apply default values
    //   const basicDefaultState = mapApiToFormState({}, mergedFieldsConfig);
    //   const advancedDefaultState = mapApiToFormState(
    //     {},
    //     advancedSettingsFields
    //   );
    //   setFormState({ ...basicDefaultState, ...advancedDefaultState });
    // }
  }, [responseData]);

  // For saving configurations
  // useEffect(() => {
  //   if (pendingSave) {
  //     saveConfigurations();
  //     setPendingSave(false);
  //   }
  // }, [formState, pendingSave]);

  const fetchJsons = () => {
    const payload = mapFormStateToApi(formState, mergedFieldsConfig);
    sendPostRequest({ appearance: { value: payload } })
      .then((data) => {
        // console.log(data);
        setOldStyleJson(data.current_prod_style_guide);
        setNewStyleJson(data.current_stage_style_guide);
        setOpen(true);
      })
      .catch((error) => {
        console.log("error: ", error);
        toast.error(error.message);
      });
  };

  const handleSelectionChange = (e) => {
    const { value } = e.target;
    setTriggerPointsDetails((prevState) => ({
      ...prevState,
      selectedValue: value,
    }));
    fetchData(
      `${APPEARANCE_GET_ENDPOINT}?appId=${currentApp?.id}&triggerPoint=${value}`
    );
  };

  const handleInputChange = (e) => {
    isSaveDisabled.current = false;
    const { name, value, type, checked } = e.target;
    if (type === "image") name = "defaultValue";
    // Allow setting field to empty string.

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImmediateChange = (e) => {
    handleInputChange(e);
    //setPendingSave(true);
  };

  // const handleFieldBlur = () => {
  //   setPendingSave(true);
  // };

  // Define saveConfigurations function
  const saveConfigurations = (targetEnv) => {
    const payload = mapFormStateToApi(formState, mergedFieldsConfig);

    sendPutRequest({ appearance: { value: payload }, targetEnv: targetEnv })
      .then(() => {
        isSaveDisabled.current = true;
        console.log("Successfully updated form state");
        toast.success(`Success!`);
      })
      .catch((error) => {
        console.log("error: ", error);
        toast.error(error.message);
      });
  };

  const pushToProductionBtn = {
    name: "Push to production",
    onClick: fetchJsons,
  };

  // Use effect to enable save buttton when state changes
  useEffect(() => {
    if (isSaveDisabled.current) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }
  }, [formState]);

  // Use the handleBlur hook
  // const { handleBlur } = useAutoSave(saveConfigurations, [formState]);

  if (loading) {
    return <AppearanceLoadingPlaceholder sections={3} />;
  }

  if (error) {
    toast.error(error);
    return <Alert severity="error">Failed to load appearance settings.</Alert>;
  }

  return (
    <>
      <div className="appearance-container">
        {/* Settings Section */}
        <div className="settings-section">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <PageHeader>Appearance</PageHeader>
            {triggerPointsDetails.options && (
              <FormControl className="select-field">
                <Select
                  value={triggerPointsDetails.selectedValue}
                  onChange={handleSelectionChange}
                  className="select-field"
                  labelId="demo-simple-select-label"
                >
                  <MenuItem value="">
                    <em>Default</em>
                  </MenuItem>
                  {triggerPointsDetails.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          {/* {triggerPointsDetails.options && <DynamicFormField
            field={triggerPointsDetails}
            value={triggerPointsDetails.selectedValue}
            onChange={handleSelectionChange}
          />
} */}

          {/* <div className="section-title-container">
            <PageSubheader>Basic Settings</PageSubheader>
            <PageSubheader
              style={{ cursor: "pointer" }}
              onClick={() => setBasicOpen(!basicOpen)}
            >
              {basicOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </PageSubheader>
          </div> */}
          <Collapse in={basicOpen} timeout="auto" unmountOnExit>
            {basicSettingsFields &&
              Object.keys(basicSettingsFields).map((section) => (
                // eslint-disable-next-line react/jsx-key
                <div key={`${section}-wrapper`}>
                  <div
                    key={section}
                    className="subsection-container flex-row flex-justify-content-space-between flex-align-items-center"
                  >
                    <SubtitleM
                      style={{
                        textAlign: "left",
                        paddingBottom: "0.25rem",
                        // borderBottom: "1px solid #ccc",
                      }}
                    >
                      {section}
                    </SubtitleM>
                  </div>
                  <div>
                    {basicSettingsFields[section]
                      .filter((i) => !i.isHidden)
                      .map((field) => (
                        <div
                          key={`${section}-${field.name}-wrapper`}
                          className="subfield-container"
                        >
                          <DynamicFormField
                            field={field}
                            value={formState[field.name]}
                            onChange={handleInputChange}
                            onImmediateChange={handleImmediateChange}
                            imageLabelStyleVariant=""
                            // onBlur={handleFieldBlur}
                          />
                        </div>
                      ))}
                    <div></div>
                  </div>
                </div>
              ))}
          </Collapse>
          <div className="section-title-container">
            <PageSubheader>Images & Icons</PageSubheader>
            <PageSubheader
              style={{ cursor: "pointer" }}
              onClick={() => setImagesIconOpen((prev) => !prev)}
            >
              {imagesIconOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </PageSubheader>
          </div>
          <Collapse in={imagesIconOpen} timeout="auto" unmountOnExit>
            {imageAndIconFields &&
              Object.keys(imageAndIconFields).map((section) => (
                // eslint-disable-next-line react/jsx-key
                <div key={`${section}-wrapper`}>
                  <div>
                    {imageAndIconFields[section]
                      .filter((i) => !i.isHidden)
                      .map((field) => (
                        <div
                          key={`${section}-${field.name}-wrapper`}
                          className="subfield-container"
                        >
                          <DynamicFormField
                            field={field}
                            value={formState[field.name]}
                            onChange={handleInputChange}
                            // onBlur={handleBlur}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </Collapse>
          <div className="section-title-container">
            <PageSubheader>Advanced Settings</PageSubheader>
            <PageSubheader
              style={{ cursor: "pointer" }}
              onClick={() => setAdvancedOpen(!advancedOpen)}
            >
              {advancedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </PageSubheader>
          </div>
          <Collapse in={advancedOpen} timeout="auto" unmountOnExit>
            {advancedSettingsFields &&
              Object.keys(advancedSettingsFields).map((section) => (
                // eslint-disable-next-line react/jsx-key
                <div key={`${section}-wrapper`}>
                  <div key={section} className="subsection-container">
                    <SubtitleM
                      style={{
                        textAlign: "left",
                        paddingBottom: "0.25rem",
                        // borderBottom: "1px solid #ccc",
                      }}
                    >
                      {section}
                    </SubtitleM>
                  </div>
                  <div>
                    {advancedSettingsFields[section]
                      .filter((i) => !i.isHidden)
                      .map((field) => (
                        <div
                          key={`${section}-${field.name}-wrapper`}
                          className="subfield-container"
                        >
                          <DynamicFormField
                            field={field}
                            value={formState[field.name]}
                            onChange={handleInputChange}
                            // onBlur={handleBlur}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </Collapse>
          {/* <div className="flex-row flex-align-items-center">
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Ready to go live with the sandbox changes?
            </Typography>
            <Button
                    variant="PrimaryButton"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Push to production
            </Button>
          </div> */}
          <Dialog
            onClose={() => setOpen(false)}
            open={open}
            title="Are you sure?"
            content={
              <ReactJsonViewCompare
                oldData={oldStyleJson}
                newData={newStyleJson}
              />
            }
            actions={
              <div className="">
                <Button
                  sx={{
                    marginRight: "15px",
                  }}
                  variant="SecondaryButton"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="padding-right-24px"
                  variant="SecondaryButton"
                  onClick={() => {
                    saveConfigurations("production");
                    setOpen(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            }
          />
        </div>

        {/* Preview Section */}
        <div className="preview-section">
          <div className="insync-flex-column ">
            <ChatAppearance
              market={currentApp?.market}
              mapFormStateToApi={mapFormStateToApi}
              formState={formState}
              mergedFieldsConfig={mergedFieldsConfig}
            />
          </div>
          {/* <ChatPreview formState={formState} /> */}
        </div>
      </div>
      <BottomActionBar
        disabledSave={disabledSave}
        handleSave={() => {
          saveConfigurations();
        }}
        buttons={[pushToProductionBtn]}
      />
    </>
  );
};

export default Appearance;
