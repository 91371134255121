/**
 * String Constants
 */
export const TOKENS = "tokens";
export const USER = "user";

// For Navbar
export const MENU_ITEMS_APP = [
  {
    title: "Home",
    path: "/",
    icon: "home",
  },
  {
    title: "AI Agent Training",
    children: [
      { title: "Websites", path: "/ai-training/websites" },
      { title: "Files", path: "/ai-training/files" },
    ],
    icon: "knowledge",
  },
  {
    title: "AI Agent Finetuning",
    icon: "controls",
    children: [
      {
        title: "Controls & Guardrails",
        path: "/ai-finetuning/controls",
      },
      {
        title: "Smart Probing",
        path: "/ai-finetuning/smart-probe",
      },
      {
        title: "AI Personality",
        path: "/ai-finetuning/ai-personality",
      },
      // {
      //   title: "AI-Fine Tuning",
      //   path: "/ai-finetuning",
      // },
    ],
  },
  {
    title: "Integrations",
    path: "/integrations",
    icon: "integrations",
  },
  {
    title: "Appearance",
    path: "/appearance",
    icon: "appearance",
  },
  {
    title: "AI Testing",
    path: "/bot-testing",
    icon: "bot-testing",
  },
  {
    title: "Insights & Analytics",
    redirect: true,
    path: "/dashboard",
    icon: "integrations",
  },
  {
    title: "Personalisation",
    path: "/personalisation",
    icon: "personalisation",
    children: [
      {
        title: "Manage Data Model",
        path: "/personalisation/data-modeling",
      },
      {
        title: "User Profile Insights",
        path: "/user_profiles",
        redirect: true,
      },
    ],
  },

  {
    title: "Access Control",
    path: "/access-control",
    icon: "integrations",
  },
];

export const MENU_ITEMS_ADMIN = [
  {
    title: "Admin Home",
    path: "/admin",
    alternatePath: "/",
    icon: "home",
  },
  {
    title: "Clients",
    path: "/clients",
    icon: "clients",
  },
  {
    title: "Users",
    path: "/users",
    icon: "users",
  },
];

/**
 * Labels
 */
export const GENERIC_LABELM_FONT_SIZE = "14px";
export const GENERIC_LABEL_L_FONT_SIZE = "16px";
export const GENERIC_BUTTON_FONT_SIZE = "18px";
export const GENERIC_BUTTON_HEIGHT = "42px";
export const GENERIC_PAGE_HEADER_FONT_SIZE = "32px";
export const GENERIC_PAGE_SUB_HEADER_FONT_SIZE = "28px";
export const GENERIC_SUBTITLEM_FONT_SIZE = "20px";
export const GENERIC_SUBTITLES_FONT_SIZE = "14px";
export const GENERIC_CAPTIONM_FONT_SIZE = "18px";
export const GENERIC_CAPTIONS_FONT_SIZE = "16px";
export const GENERIC_EDIT_FIELD_FONT_SIZE = "14px";
export const GENERIC_EDIT_PLACEHORDER_FONT_SIZE = "14px";

/**
 * CONFIGS
 */
export const USER_ROLES = [
  { title: "User", value: "user" },
  { title: "Admin", value: "admin" },
  { title: "System Admin", value: "super_admin" },
];

export const APP_USER_ROLES = [
  { title: "Client", value: "client_user" },
  { title: "Client Admin", value: "client_admin" },
  //   { title: "Editor", value: "editor" },
  { title: "Admin", value: "admin" },
  { title: "Super Admin", value: "super_admin" },
];

export const APPEARANCE_IMAGE_UPLOAD_ENDPOINT = "/v1/appearances/upload_file";
export const APPEARANCE_GET_ENDPOINT = "/v1/appearances";
export const APPEARANCE_UPDATE_ENDPOINT = "/v1/appearances";
export const APPEARANCE_PROD_DIFF_ENDPOINT = "/v1/appearances/get_diff";

export const FILES_GET_ENDPOINT = "/v1/files";
export const FILES_PRESIGNED_URL_ENDPOINT = "/v1/files/presigned_urls";
export const FILES_COMPLETE_UPLOAD_ENDPOINT = "/v1/files/complete_upload";
export const FILES_CLOUDFRONT_BASE_URL =
  "https://d1lm68cljgdm5v.cloudfront.net";

export const PLACEHOLDER_LOGO_URL_S3 = "/image (2).png";

export const CLIENT_TYPES = [
  { title: "Higher Edu - Enroll", value: "us_eduenroll" },
  { title: "Higher Edu - Engage", value: "us_devry" },
  { title: "Ecommerce", value: "us_configdefault" },
];

export const SEARCH_STYLE = {
  width: "300px",
  maxHeight: "50px",
  height: "48px",
  minHeight: "48px !important",
  margin: "0px",
  "& input": {
    padding: "10px 20px 10px 0px !important",
    border: "none",
    minHeight: "48px !important",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px 10px 10px 10px",
    border: "1px solid #9DA6B6",
    paddingLeft: "20px",
    minHeight: "46px !important",
  },
};

// Website links statuses used for filtering
export const WEBSITE_FILTER_OPTIONS = [
  "pending_review",
  "pending_sitemap_generation",
  "sitemap_generated",
  "to_be_indexed",
  "sent_for_indexing",
  "indexed",
  "to_be_skipped",
  "sent_for_skipping",
  "skipped",
  "to_be_deleted",
  "sent_for_deletion",
  "deleted",
  "failed_to_index",
  "failed_to_delete",
  "failed_to_skip",
  "failed_to_index",
];

// Status of the urls after sitemap refresh job to review
export const SITEMAP_REFRESH_REVIEW_STATUS = ["add", "delete"];

// Countries code for creating clients
export const COUNTRIES = [
  { label: "Select", value: "" },
  { label: "United States Of America (US)", value: "US" },
  { label: "United Kingdom (UK)", value: "UK" },
  { label: "Europe (EU)", value: "EU" },
  { label: "India (IN)", value: "IN" },
];


export const MODE_ENVS = [
  { label: "Sandbox", value: "staging" },
  { label: "Production", value: "production" },
];