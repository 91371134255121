export const COLORS = {
  navLinkText: "#000",
  navBg: "#F3F7FF",
  navSelected: "#6fa6d4",
  navBgOpen: "#E5E7EB",
  navBgHover: "#F1F3F5",
  navSubItemActive: "#D1D5DB",
  border: "#CBD5E1",

  PRIMARY_COLOR_1: "#0D2855",
  PRIMARY_COLOR_2: "#FF7600",
  PRIMARY_COLOR_3: "#327DF6",
  SECONDARY_COLOR_1: "#3324D5",
  SECONDARY_COLOR_2: "#F2F1FF",
  SECONDARY_COLOR_3: "#7D42FB",
  SECONDARY_COLOR_4: "#2D68FF",
  SECONDARY_COLOR_5: "#FDB52A",
  NEUTRAL_COLOR_800: "#211F54",
  NEUTRAL_COLOR_700: "#4A4566",
  NEUTRAL_COLOR_600: "#6E7191",
  NEUTRAL_COLOR_500: "#A0A3BD",
  NEUTRAL_COLOR_400: "#DCDDEB",
  NEUTRAL_COLOR_300: "#EFF0F6",
  NEUTRAL_COLOR_200: "#F7F7FC",
  NEUTRAL_COLOR_100: "#FFFFFF",
  SYSTEM_BLUE_400: "#086CD9",
  SYSTEM_BLUE_300: "#1D88FE",
  SYSTEM_BLUE_200: "#8FC3FF",
  SYSTEM_BLUE_100: "#EAF4FF",
  SYSTEM_GREEN_400: "#11845B",
  SYSTEM_GREEN_300: "#05C168",
  SYSTEM_GREEN_200: "#7FDCA4",
  SYSTEM_GREEN_100: "#DEF2E6",
  SYSTEM_RED_400: "#DC2B2B",
  SYSTEM_RED_300: "#FF5A65",
  SYSTEM_RED_200: "#FFBEC2",
  SYSTEM_RED_100: "#FFEFF0",
  SYSTEM_ORANGE_400: "#D5691B",
  SYSTEM_ORANGE_300: "#FF9E2C",
  SYSTEM_ORANGE_200: "#FFD19B",
  SYSTEM_ORANGE_100: "#FFF3E4",
};
