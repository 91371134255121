import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import TopBar from "./components/TopBar";
import Navbar from "./Navbar";
import PageWindow from "./components/PageWindow";
import AdminHome from "./pages/Home/AdminHome";
import Clients from "./pages/Clients/Clients";
import ManageClient from "./pages/Clients/ManageClient";
import Users from "./pages/Users/Users";
import PrivateRoute from "./PrivateRoute";
import Websites from "./pages/Knowledge/Websites/Websites";
import Files from "./pages/Knowledge/Files";
import Controls from "./pages/Controls/Controls";
import Appearance from "./pages/Appearance/Appearance";
import Personality from "./pages/Personality/Personality";
import AccessControl from "./pages/AccessControl/AccessControl";
import { saveApps } from "./slices/AppsSlice";
import BotTesting from "./pages/BotTesting/BotTesting";
import UserProbing from "./pages/UserProbing/UserProbing";
import Integrations from "./pages/Integrations/Integrations";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import DataModeling from "./pages/Personalisation/DataModeling";

export default function NavigationRoutes() {
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const apps = useSelector((state) => state?.apps.list || []);
  const location = useLocation();
  const dispatch = useDispatch();

  const ignoredPaths = ["/fonts/"]; // Add all static asset paths here

  const isIgnoredPath = ignoredPaths.some((path) =>
    location.pathname.startsWith(path)
  );


  useEffect(() => {
    let selectedApp = localStorage.getItem("selectedApp");
    if (!currentApp && !selectedApp) {
      const currentPathSegments = location.pathname.split("/");
      const currentAppNameFromUrl = currentPathSegments[1];
      selectedApp = apps?.find((app) => app.url_code === currentAppNameFromUrl);
      if (selectedApp) {
        dispatch(saveApps(selectedApp));
      }
    }
  }, [location, dispatch]);

  return (
    <div>
      <TopBar />
      <Navbar />
      <PageWindow>
        <Routes>
          <Route path="/" element={<AdminHome />} />
          <Route path="/admin" element={<AdminHome />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/admin/new-client" element={<ManageClient />} />
          <Route path="/users" element={<Users />} />
          <Route
            path="/:app_name/"
            element={<PrivateRoute element={ManageClient} edit={true} />}
          />
          {/* AI Agent Training */}
          <Route
            path="/:app_name/ai-training/websites"
            element={<PrivateRoute element={Websites} />}
          />
          <Route
            path="/:app_name/ai-training/files"
            element={<PrivateRoute element={Files} />}
          />
          {/* AI Agent Finetuning */}
          <Route
            path="/:app_name/ai-finetuning/controls"
            element={<PrivateRoute element={Controls} />}
          />
          <Route
            path="/:app_name/ai-finetuning/ai-personality"
            element={<PrivateRoute element={Personality} />}
          />
          <Route
            path="/:app_name/ai-finetuning/smart-probe"
            element={<PrivateRoute element={UserProbing} />}
          />
          <Route
            path="/:app_name/appearance"
            element={<PrivateRoute element={Appearance} />}
          />
          <Route
            path="/:app_name/bot-testing"
            element={<PrivateRoute element={BotTesting} />}
          />
          <Route
            path="/:app_name/access-control"
            element={<PrivateRoute element={AccessControl} />}
          />
          {/* <Route
            path="/:app_name/insights-analytics"
            element={<PrivateRoute element={ComingSoon} />}
          /> */}
          <Route
            path="/:app_name/personalisation/data-modeling"
            element={<PrivateRoute element={DataModeling} />}
          />
          {/* <Route
            path="/:app_name/personalisation/user-profile-insights"
            element={<PrivateRoute element={ComingSoon} />}
          /> */}

          <Route
            path="/:app_name/integrations"
            element={<PrivateRoute element={Integrations} />}
          />
          {!isIgnoredPath && (
            <Route path="*" element={<div>404 Page Not Found</div>} />
          )}
        </Routes>
      </PageWindow>
    </div>
  );
}
