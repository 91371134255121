import React, { useEffect, useState } from "react";

import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import Selector from "./Selector";
import AutoCompleteDialog from "./AutoCompleteDialog";
import { COLORS } from "../constants/Colors";

const RenderTableElements = (props) => {
  switch (props.type) {
    case "textfield":
      return (
        <TextField
          margin="dense"
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          aria-label={props.label}
          placeholder={props.placeholder}
          error={props.error}
          sx={{
            width: "100% !important",
            margin: "0px",
            maxHeight: "50px",
            minHeight: "50px",
            padding: "0px !important",
            "& input": {
              border: "none",
              maxWidth: props.maxWidth || "300px",
              minWidth: props.minWidth || "300px",
              padding: "0px",
            },
            "& .MuiOutlinedInput-root": {
              padding: "0px !important",
              margin: "0px !important",
              height: "20px",
            },
            ...props.customStyle,
          }}
        />
      );
    case "textarea":
      return (
        <TextField
          margin="dense"
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          aria-label={props.label}
          placeholder={props.placeholder}
          error={props.error}
          multiline
          minRows={2}
          maxRows={2}
          sx={{
            width: "100% !important",
            margin: "0px",

            padding: "0px !important",
            "& textarea": {
              border: "none",
              maxWidth: props.maxWidth || "400px",
              minWidth: props.minWidth || "300px",
              padding: "8px !important",
              height: "20px",
              maxHeight: "22px",
            },
            "& input": {
              border: "none",
              maxWidth: "300px",
              padding: "0px",
            },
            "& .MuiOutlinedInput-root": {
              padding: "0px !important",
              margin: "0px !important",
              height: "20px",
            },
            ...props.customStyle,
          }}
        />
      );
    case "selector":
      return (
        <Selector
          items={props.items}
          error={props.error}
          customStyles={{
            height: "20px",
            width: "150px",
            zIndex: props.zIndex,
            "& input": {
              border: "none",
              width: "300px",
              padding: "0px",
            },
            "& .MuiSelect-select": {
              paddingLeft: "0px",
            },
            "& .MuiOutlinedInput-root": {
              padding: "0px",
              height: "20px",
            },
            "& .MuiPaper-root": {
              zIndex: props.zIndex + " !important",
            },
          }}
          defaultValue={props.value}
          setValue={props.setValue}
          onBlur={props.onBlur}
        />
      );
    case "tags":
      return (
        <AutoCompleteDialog
          value={props.value}
          title={props.title}
          label={props.label}
          infoText={props.infoText}
          saveButtonClick={props.saveButtonClick}
          cancelButtonClick={props.cancelButtonClick}
          options={props.options}
        />
      );
  }
  return <div className="tableDivDefaultElement">{props.value}</div>;
};

export default function Table({
  tableContents = [], // two dimensional array containing rows and columns
  rowHeaders = [],
  customStyle = {},
  customTableStyle = {},
  rowsPerPageProp = 10,
  rowCount = null,
  rowsPerPageOptions = [10, 20, 50, 100],
  pagination = false,
  onChangeRowsPerPage,
  onChangePage,
  serverSidePagination = false,
  pageNumber = 0,
  emptyText = "Nothing To Display",
  currentOrderBy,
  currentOrder,
  onRequestSort,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageProp);

  const count = rowCount ? rowCount : tableContents.length;

  useEffect(() => {
    setPage(pageNumber);
  }, [pageNumber]);

  const handleChangePage = (event, newPage) => {
    if (onChangePage) {
      onChangePage(newPage);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(parseInt(event.target.value, 10));
    }
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedTable = (contents) => {
    if (pagination && !serverSidePagination) {
      return contents.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    }

    return contents;
  };

  return (
    <TableContainer
      sx={{
        ...customStyle,
        width: "100%",
      }}
    >
      <MuiTable style={{ ...customTableStyle }} stickyHeader>
        <TableHead>
          <TableRow>
            {rowHeaders.length > 0 &&
              rowHeaders
                .filter((el) => el.columnData != null)
                .map((header) => {
                  // Default to true
                  const isSortable = header.sortable !== false;
                  return (
                    <TableCell
                      align="left"
                      key={`table-header-${header.id}`}
                      sx={header?.style}
                      sortDirection={
                        currentOrderBy === header.id ? currentOrder : false
                      }
                    >
                      {isSortable && onRequestSort ? (
                        <TableSortLabel
                          active={currentOrderBy === header.id}
                          direction={
                            currentOrderBy === header.id ? currentOrder : "asc"
                          }
                          onClick={() => onRequestSort(header.id)}
                        >
                          {header.columnData}
                        </TableSortLabel>
                      ) : (
                        header.columnData
                      )}
                    </TableCell>
                  );
                })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableContents.length > 0 ? (
            paginatedTable(tableContents).map((row, rowIndex) => (
              <TableRow hover key={`table-row-${rowIndex}`}>
                {row
                  .filter((col) => col.element != null)
                  .map((col) => (
                    <TableCell
                      align="left"
                      key={`table-col-${col.id}`}
                      sx={{
                        height: "65px",
                        ...(col?.style ? col.style : {}),
                      }}
                    >
                      {col.element ? (
                        <RenderTableElements key={col.id} {...col.element} />
                      ) : (
                        col.columnData
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                sx={{ textAlign: "center", height: "66px" }}
                colSpan={rowHeaders.length}
              >
                {emptyText}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>

      {pagination && (
        <TablePagination
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          count={count}
          page={page}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
          slotProps={{
            actions: {
              previousButton: {
                sx: {
                  backgroundColor: COLORS.PRIMARY_COLOR_3,
                  borderRadius: "10px",
                  color: "#fff",
                  padding: "10px",
                  ":hover": {
                    backgroundColor: "#2966C7",
                  },
                  ":disabled": {
                    backgroundColor: "#d0e1fd",
                  },
                },
              },
              nextButton: {
                sx: {
                  backgroundColor: COLORS.PRIMARY_COLOR_3,
                  borderRadius: "10px",
                  marginLeft: "10px",
                  padding: "10px",
                  color: "#fff",
                  ":hover": {
                    backgroundColor: "#2966C7",
                  },
                  ":disabled": {
                    backgroundColor: "#d0e1fd",
                  },
                },
              },
            },
          }}
          sx={{
            ".MuiTablePagination-toolbar": {
              padding: "0",
              justifyContent: "flex-end",
            },
            ".MuiTablePagination-spacer": {
              display: "none",
            },
            ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
              {
                padding: "0",
              },
            ".MuiTablePagination-displayedRows": {
              border: "1px solid #e0e0e0",
              borderRadius: "10px",
              padding: "5px 15px",
            },
            ".MuiTablePagination-selectLabel": {
              display: "none",
            },
            ".MuiTablePagination-input": {
              display: "none",
            },
          }}
        />
      )}
    </TableContainer>
  );
}
