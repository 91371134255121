import React, { useEffect, useState } from "react";
import { Button, Chip, Stack, TextField, Checkbox } from "@mui/material";
import { isEmpty } from "lodash";
import SideBar from "../../../components/SideBar";
import useFetch from "../../../hooks/useFetch";
import Table from "../../../components/Table";
import TabularLoadingPlaceholder from "../../../components/LoadingPlaceholders/TabularLoadingPlaceholder";
import { SubtitleS } from "../../../components/Typography";
import usePostRequest from "../../../hooks/usePostRequest";
import {
  convertUnderscoreToSpaces,
  formatDate,
  getStatusChipProps,
  getStringParamsFromObject,
} from "../../../utils";
import SearchBar from "../../../components/SearchBar";
import { FilterButton } from "../../../components/utils/InsyncIconsUtil";
import OptionMenu from "../../../components/OptionMenu";
import {
  WEBSITE_FILTER_OPTIONS,
  SITEMAP_REFRESH_REVIEW_STATUS,
} from "../../../constants/Constants";

// Creating filter options for status
const FILTER_OPTIONS = WEBSITE_FILTER_OPTIONS.map((el) => {
  return {
    title: convertUnderscoreToSpaces(el),
    value: el,
    selected: false,
    type: "status",
  };
});

// Creating filter options for sitemap refresh review status
const SITEMAP_REVIEW_FILTER_OPTIONS = SITEMAP_REFRESH_REVIEW_STATUS.map(
  (el) => ({
    title: convertUnderscoreToSpaces(el),
    value: el,
    selected: false,
    type: "sitemapReviewStatus",
  })
);

export default function UrlsSidebar({
  website,
  currentApp,
  tokens,
  isOpen,
  onClose,
}) {
  // SECTION: State Initialization
  const [urls, setUrls] = useState({});
  const [statusFilters, setStatusFilters] = useState(
    FILTER_OPTIONS.map((opt, index) => {
      return {
        ...opt,
        onClick: () => {
          setStatusFilters((prev) => {
            let newArr = [...prev];
            newArr[index].selected = !newArr[index].selected;
            return newArr;
          });
        },
      };
    })
  );
  const [sitemapReviewFilters, setSitemapReviewFilters] = useState(
    SITEMAP_REVIEW_FILTER_OPTIONS.map((opt, index) => {
      return {
        ...opt,
        onClick: () => {
          setSitemapReviewFilters((prev) => {
            let newArr = [...prev];
            newArr[index].selected = !newArr[index].selected;
            return newArr;
          });
        },
      };
    })
  );
  const [isAddLinkBoxOpen, setIsAddLinkBoxOpen] = useState(false);
  const [addLinkBoxContent, setAddLinkBoxContent] = useState("");
  const [search, setSearch] = useState("");
  const [paginationParams, setPaginationParams] = useState({
    page_size: 100,
    page: 0,
    order_by: "url",
    order: "asc",
  });
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [currentUrl, setCurrentUrl] = useState("");

  // SECTION: Data Fetching
  const {
    data: urlData,
    loading,
    fetchData,
  } = useFetch(
    `/v1/apps/${currentApp.id}/websites/${website.id}/urls`,
    tokens,
    [],
    [],
    false
  );

  const { sendPostRequest, loading: insertingUrls } = usePostRequest(
    `/v1/apps/${currentApp.id}/websites/${website.id}/urls/bulk_create`,
    tokens
  );

  // New usePostRequest for update_status
  const { sendPostRequest: sendUpdateStatusRequest } = usePostRequest(
    `/v1/apps/${currentApp.id}/websites/${website.id}/urls/update_status`,
    tokens
  );

  // SECTION: Handlers and Functions

  // Handler for sorting
  const handleRequestSort = (property) => {
    // Do not sort on the checkbox column
    if (property === "checkbox-all") {
      return;
    }
    const isAsc =
      paginationParams.order_by === property &&
      paginationParams.order === "asc";
    setPaginationParams({
      ...paginationParams,
      order_by: property,
      order: isAsc ? "desc" : "asc",
      page: 0, // Reset to first page when sorting changes
    });
  };

  const onChangeRowsPerPage = (value) => {
    setPaginationParams({ ...paginationParams, page_size: value });
  };

  const onChangePage = (newPage) => {
    setPaginationParams({
      ...paginationParams,
      page: newPage,
    });
  };

  // Handler for adding links (moved from onClick)
  const handleAddLinks = async () => {
    const urlsToAdd = addLinkBoxContent
      .split("\n")
      .map((e) => e.trim())
      .filter((e) => e !== "");
    if (urlsToAdd.length === 0) {
      // If there are no valid URLs, don't proceed
      return;
    }
    const response = await sendPostRequest({
      urls: urlsToAdd,
    });
    if (response.message === "Ok") {
      fetchData(currentUrl);
      setAddLinkBoxContent("");
      setIsAddLinkBoxOpen(false);
    }
  };

  // Handlers for checkboxes
  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedUrls.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUrls, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUrls.slice(1));
    } else if (selectedIndex === selectedUrls.length - 1) {
      newSelected = newSelected.concat(selectedUrls.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUrls.slice(0, selectedIndex),
        selectedUrls.slice(selectedIndex + 1)
      );
    }

    setSelectedUrls(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = urls?.data?.map((n) => n.id);
      setSelectedUrls(newSelecteds);
      return;
    }
    setSelectedUrls([]);
  };

  // Handlers for bulk updating URLs
  const bulkUpdateUrls = async (status) => {
    try {
      const response = await sendUpdateStatusRequest({
        status: status,
        url_ids: selectedUrls,
      });
      if (response) {
        fetchData(currentUrl);
        setSelectedUrls([]);
      }
    } catch (error) {
      console.error("Error updating URLs:", error);
    }
  };

  const handleIndexClick = async () => {
    await bulkUpdateUrls("to_be_indexed");
  };

  const handleSkipClick = async () => {
    await bulkUpdateUrls("to_be_skipped");
  };

  const handleDeleteClick = async () => {
    await bulkUpdateUrls("to_be_deleted");
  };

  // Render the Website details (Url, Links Count etc.) on the top in the sidebar
  const InfoSubtitle = (key, value, keyStyle = {}, valueStyle = {}) => {
    const isUrl = key.toLowerCase() === "url";

    return (
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        paddingBottom={"0px"}
        sx={{
          height: "25px",
        }}
      >
        <SubtitleS
          style={{
            color: "#000",
            width: "100px",
            textAlign: "left",
            fontWeight: 300,
            ...keyStyle,
          }}
        >
          {key}
        </SubtitleS>
        {isUrl ? (
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#000",
              fontWeight: 400,
              textDecoration: "underline",
              ...valueStyle,
            }}
          >
            {value}
          </a>
        ) : (
          <SubtitleS
            style={{
              color: "#000",
              fontWeight: 400,
              ...valueStyle,
            }}
          >
            {value}
          </SubtitleS>
        )}
      </Stack>
    );
  };

  // SECTION: Effects

  useEffect(() => {
    const params = {
      ...paginationParams,
      search: search.trim(),
    };

    let url = `/v1/apps/${currentApp.id}/websites/${
      website.id
    }/urls?${getStringParamsFromObject(params)}`;

    let selectedStatusFilters = statusFilters
      .filter((s) => s.selected)
      .map((s) => s.value)
      .join(",");

    let selectedSitemapReviewStatuses = sitemapReviewFilters
      .filter((s) => s.selected)
      .map((s) => s.value)
      .join(",");

    if (!isEmpty(selectedStatusFilters)) {
      url += "&status=" + selectedStatusFilters;
    }

    if (!isEmpty(selectedSitemapReviewStatuses)) {
      url += "&sitemap_refresh_review_status=" + selectedSitemapReviewStatuses;
    }

    // Store current URL
    setCurrentUrl(url);
    fetchData(url);
  }, [
    paginationParams,
    statusFilters,
    sitemapReviewFilters,
    search,
    currentApp.id,
    website.id,
    fetchData,
  ]);

  useEffect(() => {
    if (urlData) {
      setUrls(urlData);
      // Clear selected URLs when data changes
      setSelectedUrls([]);
    }
  }, [urlData]);

  useEffect(() => {
    setPaginationParams((prevParams) => ({
      ...prevParams,
      page: 0,
    }));
  }, [search, statusFilters, sitemapReviewFilters]);

  // SECTION: Render

  return (
    <SideBar
      key={(website.id ? website.id : "new") + "-options-view-links-drawer"}
      title={website.url}
      windowWidth="75%"
      isOpen={isOpen}
      onClose={onClose}
    >
      {/* Basic Info section starts */}
      <Stack
        flexDirection={"column"}
        sx={{ width: "500px", marginBottom: "24px" }}
      >
        {InfoSubtitle(
          "Website",
          website.url,
          {},
          { textDecoration: "underline" }
        )}
        {InfoSubtitle(
          "Total URLs",
          urls?.total?.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        )}
        {InfoSubtitle("Last updated", formatDate(website.updated_at))}
      </Stack>
      {/* Basic Info section ends */}

      {/* Actionable Items section starts */}
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ width: "500px", marginBottom: "12px" }}
      >
        {/* Search Bar starts */}
        <SearchBar
          data={urls}
          onSearchResults={(filteredData) => {
            if (filteredData) {
              setUrls(filteredData);
            }
          }}
          triggerOn="onEnter"
          url={`/v1/apps/${currentApp.id}/websites/${website.id}/urls`}
          queryParam={`${getStringParamsFromObject(paginationParams)}&search`}
          searchKeys={["url"]}
          customStyle={{ minWidth: "400px !important" }}
          onSearch={(value) => {
            setSearch(value);
          }}
        />
        {/* Search Bar ends */}

        {/* Status based filter starts */}
        <OptionMenu
          title={"Status"}
          enableCheckBoxes={true}
          zIndex={21474836}
          options={statusFilters}
          customStyle={{ width: "40ch" }}
          button={
            <Button
              title="Status"
              variant="SecondaryButton"
              sx={{
                margin: "0px 5px 0px 10px",
              }}
            >
              <FilterButton />
              {statusFilters.some((el) => el.selected) && (
                <span className="selected-filters-span">
                  {statusFilters.filter((el) => el.selected).length}
                </span>
              )}
            </Button>
          }
        />
        {/* Status based filter ends */}

        {/* Sitemap Review Status Filter starts */}
        <OptionMenu
          title={"Sitemap Review Status"}
          enableCheckBoxes={true}
          zIndex={21474836}
          options={sitemapReviewFilters}
          customStyle={{ width: "40ch" }}
          button={
            <Button
              title="Sitemap Review Status"
              variant="SecondaryButton"
              sx={{
                margin: "0px 5px 0px 5px",
              }}
            >
              <FilterButton />
              {sitemapReviewFilters.some((el) => el.selected) && (
                <span className="selected-filters-span">
                  {sitemapReviewFilters.filter((el) => el.selected).length}
                </span>
              )}
            </Button>
          }
        />
        {/* Sitemap Review Status Filter ends */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            title="Add Links"
            variant="LightBlueBGButton"
            sx={{
              minWidth: "150px",
              margin: "0px 5px 0px 5px",
            }}
            onClick={() => {
              setIsAddLinkBoxOpen(true);
            }}
          >
            + Links
          </Button>

          <Button
            title="Index"
            variant="PrimaryButton"
            sx={{ margin: "0px 5px 0px 5px" }}
            onClick={handleIndexClick}
            disabled={selectedUrls.length === 0}
          >
            Index
          </Button>

          <Button
            title="Skip"
            variant="PrimaryButton"
            sx={{ margin: "0px 5px 0px 5px" }}
            onClick={handleSkipClick}
            disabled={selectedUrls.length === 0}
          >
            Skip
          </Button>

          <Button
            title="Delete"
            variant="PrimaryButton"
            sx={{ margin: "0px 5px 0px 5px" }}
            onClick={handleDeleteClick}
            disabled={selectedUrls.length === 0}
          >
            Delete
          </Button>
        </div>
      </Stack>
      {/* Actionable Items section ends */}

      {/* Add a Link section starts */}
      {isAddLinkBoxOpen && (
        <Stack
          flexDirection={"column"}
          key="input-add-links"
          sx={{
            width: "1",
            marginBottom: "12px",
            borderRadius: "10px 10px 10px 10px",
            border: "1px solid #9DA6B6",
          }}
        >
          <TextField
            type="text"
            disabled={insertingUrls}
            margin="dense"
            name={"Add Links"}
            key={`Add Links Text Box-${website.id}`}
            value={addLinkBoxContent}
            onChange={(e) => {
              setAddLinkBoxContent(e.target.value);
            }}
            sx={{
              width: "99%",
              "& .MuiInputBase-input": {
                padding: "25px 20px 25px 20px",
                border: "#fff",
              },
            }}
            multiline
            minRows={4}
            maxRows={4}
            placeholder={"Add Links"}
            aria-label={"Add Links"}
          />
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            paddingBottom={"0px"}
            sx={{ margin: "20px" }}
          >
            <Button
              onClick={(e) => {
                setIsAddLinkBoxOpen(false);
              }}
              sx={{ marginRight: "8px" }}
              variant="SecondaryButton"
            >
              Cancel
            </Button>
            <Button
              variant="PrimaryButtonBlue"
              disabled={isEmpty(addLinkBoxContent.trim())}
              onClick={handleAddLinks}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      )}
      {/* Add a Link section ends */}

      {/* Show Filtered URLs count when search or filters are applied */}
      {(search.trim() !== "" || statusFilters.some((el) => el.selected)) &&
        !loading &&
        urls?.count !== undefined && (
          <SubtitleS
            style={{ textAlign: "left", color: "#000", marginTop: "0px" }}
          >{`Filtered URLs: ${urls.count}`}</SubtitleS>
        )}

      {loading ? (
        <TabularLoadingPlaceholder numRows={3} numColumns={3} />
      ) : (
        <Table
          customTableStyle={{
            width: "100%",
          }}
          customStyle={{
            overflowX: "visible",
          }}
          tableContents={urls?.data?.map((url, i) => [
            {
              id: "checkbox" + url.id,
              element: {
                value: (
                  <Checkbox
                    checked={selectedUrls.indexOf(url.id) !== -1}
                    onChange={(event) => handleCheckboxClick(event, url.id)}
                  />
                ),
              },
            },
            {
              id: "url" + url.id,
              element: { value: url.url },
            },
            {
              id: "status" + url.id,
              element: {
                value: (
                  <Chip
                    label={getStatusChipProps(url.status).label}
                    color={getStatusChipProps(url.status).color}
                    variant="filled"
                    size="small"
                  />
                ),
              },
            },
          ])}
          rowHeaders={[
            {
              id: "checkbox-all",
              sortable: false,
              columnData: (
                <Checkbox
                  indeterminate={
                    selectedUrls.length > 0 &&
                    selectedUrls.length < urls?.data?.length
                  }
                  checked={
                    urls?.data?.length > 0 &&
                    selectedUrls.length === urls?.data?.length
                  }
                  onChange={handleSelectAllClick}
                />
              ),
            },
            { id: "urls", columnData: "URLS" },
            {
              id: "status",
              columnData: "Status",
            },
          ]}
          pageNumber={paginationParams.page}
          rowsPerPageProp={paginationParams.page_size}
          rowsPerPageOptions={[1, 10, 25, 50, 100, 500, 1000, 2000]}
          pagination
          rowCount={urls.count}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          serverSidePagination
          currentOrderBy={paginationParams.order_by}
          currentOrder={paginationParams.order}
          onRequestSort={handleRequestSort}
        />
      )}
    </SideBar>
  );
}
