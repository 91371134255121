import React from "react";
import SideBar from "../../components/SideBar";
import Table from "../../components/Table";
import { Button, Checkbox } from "@mui/material";
import TabularLoadingPlaceholder from "../../components/LoadingPlaceholders/TabularLoadingPlaceholder";

export default function DataModelingTableSidebar({
  isOpen,
  onClose,
  userProfileStructures,
  handleChange,
  handleSave,
  disabledSave,
  currentApp,
  tokens,
  addNewStructure,
  loading,
}) {
  return (
    <SideBar
      key={"data-modeling-sidebar"}
      isOpen={isOpen}
      windowWidth="100%"
      maxWidth="88%"
      onClose={onClose}
      title={"Data Model Definition"}
      zIndex={1299}
      footer={
        <div
          className="flex-row flex-justify-content-end flex-align-items-center margin-top-8px margin-bottom-10px"
          style={{ maxWidth: "88%", marginLeft: "24px"}}
        >
          <Button
            disabled={disabledSave}
            variant="PrimaryButton"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className="flex-row flex-justify-content-end flex-align-items-center full-width padding-left-24px  margin-top-8px margin-bottom-10px">
        <Button variant="PrimaryButtonBlue" onClick={addNewStructure}>
          + Add New
        </Button>
      </div>

      {loading ? (
        <TabularLoadingPlaceholder numRows={3} numCols={5} />
      ) : (
        <Table
          customTableStyle={{
            width: "100%",
            marginTop: "30px",
            "& .MuiTableCell-root":{
              height: "56px"
            }
          }}
          customStyle={{
            marginRight: "40px",
            overflowX: "visible",
            marginBottom: "40px",
          }}
          emptyText="Add keys to create user profile structure"
          rowHeaders={[
            {
              columnData: "Field Name",
              id: "name",
            },
            {
              columnData: "Definition",
              id: "definition",
            },
            {
              columnData: "Type",
              id: "field_type",
            },
            {
              columnData: "Use For User Profile",
              id: "is_user_profile_description",
            },
            {
              columnData: "Use For Program Profile",
              id: "is_program_detail",
            },
          ]}
          tableContents={userProfileStructures?.map(
            (userProfileStructure, index) => {
              return [
                {
                  id:
                    (userProfileStructure.id
                      ? userProfileStructure.id
                      : "new") + "-name",
                  element: {
                    type: "textfield",
                    value: userProfileStructure.name,
                    onChange: (e) =>
                      handleChange(index, "name", e.target.value),
                    label: "name",
                    placeholder: "Enter Name",
                    maxWidth: "230px",
                    minWidth: "230px",
                    error: userProfileStructure.error,
                  },
                },
                {
                  id:
                    (userProfileStructure.id
                      ? userProfileStructure.id
                      : "new") + "-definition",
                  element: {
                    type: "textarea",
                    maxWidth: "500px",
                    minWidth: "500px",
                    value: userProfileStructure.definition,
                    onChange: (e) =>
                      handleChange(index, "definition", e.target.value),
                    label: "definition",
                    placeholder: "Enter Definition",
                    error: userProfileStructure.error,
                  },
                },
                {
                  id:
                    (userProfileStructure.id
                      ? userProfileStructure.id
                      : "new") + "-field_type",
                  element: {
                    type: "selector",
                    items: [
                      {
                        title: "Text",
                        value: "text",
                      },
                      {
                        title: "Number",
                        value: "number",
                      },
                      {
                        title: "Boolean",
                        value: "boolean",
                      },
                      {
                        title: "Array",
                        value: "array",
                      },
                      {
                        title: "JSON",
                        value: "json",
                      },
                    ],
                    value: userProfileStructure.field_type,
                    setValue: (val) => {
                      handleChange(index, "field_type", val);
                    },
                    error: userProfileStructure.error,
                  },
                },
                {
                  id:
                    (userProfileStructure.id
                      ? userProfileStructure.id
                      : "new") + "-is_user_profile_description",
                  element: {
                    value: (
                      <Checkbox
                        checked={
                          userProfileStructure.is_user_profile_description
                        }
                        onChange={(e) =>
                          handleChange(
                            index,
                            "is_user_profile_description",
                            e.target.checked
                          )
                        }
                      />
                    ),
                  },
                },
                {
                  id:
                    (userProfileStructure.id
                      ? userProfileStructure.id
                      : "new") + "-is_program_detail",
                  element: {
                    value: (
                      <Checkbox
                        checked={userProfileStructure.is_program_detail}
                        onChange={(e) =>
                          handleChange(
                            index,
                            "is_program_detail",
                            e.target.checked
                          )
                        }
                      />
                    ),
                  },
                },
              ];
            }
          )}
        />
      )}
    </SideBar>
  );
}
