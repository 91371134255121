import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import Dialog from "./Dialog";
import { SubtitleS } from "./Typography";

/**
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.title="Add Tags"] - The title of the dialog.
 * @param {string} [props.infoText="Start typing to search for and add tags"] - description of the dialog.
 * @param {string} [props.label="Add Tags +"] - The label for the Chip component.
 * @param {Array} [props.value=[]] - The initial list of selected tags.
 * @param {Array} [props.options=[]] - The list of available tag options.
 * @param {Function} props.saveButtonClick - Callback function to handle save button click.
 * @param {Function} props.cancelButtonClick - Callback function to handle cancel button click.
 *
 * @returns {JSX.Element} The rendered AutoCompleteDialog component.
 */

const AutoCompleteDialog = ({
  title = "Add Tags",
  infoText = "Start typing to search for and add tags",
  label = "Add Tags +",
  value = [],
  options = [],
  saveButtonClick,
  cancelButtonClick,
}) => {
  const [open, setOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    setSelectedTags(value);
  }, [value]);

  const handleTagsChange = (event, newValue) => {
    setSelectedTags(newValue);
  };

  const handleSaveTags = () => {
    saveButtonClick(selectedTags);
    setOpen(false);
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setSelectedTags((prevTags) =>
      prevTags.filter((tag) => tag !== tagToDelete)
    );
  };

  return (
    <>
      <Chip
        label={
          selectedTags.length > 2
            ? selectedTags.slice(0, 2).join(", ") +
              " +" +
              (selectedTags.length - 2)
            : selectedTags.length > 0
            ? selectedTags.map((tag) => tag).join(", ")
            : label
        }
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{ cursor: "pointer", maxWidth: "200px", whiteSpace: "nowrap" }}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        title={title}
        content={
          <Box sx={{ width: "100%", px: 2 }}>
            <SubtitleS sx={{ mb: 2 }}>{infoText}</SubtitleS>
            <Autocomplete
              open={selectOpen}
              onOpen={() => {
                setSelectOpen(true);
              }}
              onClose={(e, reason) => {
                if (reason === "selectOption") return;
                setSelectOpen(false);
              }}
              multiple
              freeSolo
              options={options}
              value={selectedTags}
              onChange={handleTagsChange}
              filterSelectedOptions
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add tags"
                  sx={{
                    width: "100% !important", // Use !important to override
                    "& .MuiOutlinedInput-root": {
                      paddingRight: 0,
                      paddingLeft: 0,
                    },
                  }}
                />
              )}
              sx={{ mb: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                maxHeight: "150px",
                overflowY: "auto",
              }}
            >
              {selectedTags.map((option, index) => (
                <Chip
                  key={index}
                  label={option}
                  onDelete={handleDeleteTag(option)}
                />
              ))}
            </Box>
          </Box>
        }
        actions={
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "10px",
              marginTop: 1,
            }}
          >
            <Button
              variant="SecondaryButton"
              onClick={() => {
                cancelButtonClick();
                setSelectedTags(value);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{ marginLeft: "8px" }}
              variant="PrimaryButton"
              onClick={handleSaveTags}
            >
              Add
            </Button>
          </Stack>
        }
      />
    </>
  );
};

export default AutoCompleteDialog;
