import { Button, Drawer, IconButton, Stack } from "@mui/material";
import React from "react";
import { PageSubheader } from "./Typography";
import { CloseIcon } from "./utils/InsyncIconsUtil";

/**
 * A sidebar component that contains a drawer and a button to toggle the drawer.
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The content to display inside the drawer.
 * @param {("left"|"right"|"top"|"bottom")} [props.anchor="right"] - The side of the screen
 * from which the drawer will slide.
 * @param {string} [props.button=props.anchor] - The text of the button which opens the drawer.
 * @param {Object} [props.drawerProps={}] - Additional props to pass to the Drawer component.
 *
 * @returns {JSX.Element} The rendered sidebar component.
 */

/**
 * SideBar component renders a sidebar drawer with customizable properties.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The content to be displayed inside the drawer.
 * @param {string} [props.anchor="right"] - The side from which the drawer will appear. Can be "left", "right", "top", or "bottom".
 * @param {string} [props.button=anchor] - The text or element to be displayed inside the button that triggers the drawer.
 * @param {Object} [props.drawerProps={}] - Additional props to be passed to the Drawer component.
 * @param {Function} [props.onButtonClick=() => {}] - Callback function to be called when the button is clicked.
 * @param {Object} [props.buttonSx={}] - The style object for the button.
 * @param {Object} [props.paperProps={}] - Additional props to be passed to the Paper component inside the Drawer.
 * @param {string} [props.windowWidth="45%"] - The width of the drawer.
 * @param {string} [props.maxWidth="1200px"] - The maximum width of the drawer.
 * @param {number} [props.zIndex=21474834] - The z-index of the Drawer component.
 * @param {string} [props.title=""] - The title to be displayed at the top of the drawer.
 * @param {string|React.ReactNode} [props.footer=""] - The footer content to be displayed at the bottom of the drawer.
 * @param {boolean|null} [props.isOpen=null] - Controls the open state of the drawer. If null, the component manages its own state.
 * @param {Function|null} [props.onClose=null] - Callback function to be called when the drawer is closed.
 * 
 * @returns {React.ReactElement} The rendered SideBar component.
 */

export default function SideBar({
  children,
  anchor = "right",
  button = anchor,
  drawerProps = {},
  onButtonClick = () => {},
  buttonSx = {},
  paperProps = {},
  windowWidth = "45%",
  maxWidth="1200px",
  zIndex = 21474834,
  title = "",
  footer = "",
  isOpen = null,
  onClose = null,
}) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (updatedOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (updatedOpen && !open) {
      onButtonClick();
    }

    setOpen(updatedOpen);
  };

  // Determine whether to use internal state or external control
  const drawerOpen = isOpen !== null ? isOpen : open;
  const handleClose = onClose || toggleDrawer(false);

  return (
    <React.Fragment key={anchor + "-anchor-drawer-key"}>
      {/* Only render the button if isOpen is not externally controlled */}
      {isOpen === null && (
        <Button sx={{ ...buttonSx }} onClick={toggleDrawer(true)}>
          {button}
        </Button>
      )}
      <Drawer
        anchor={anchor}
        open={drawerOpen}
        onClose={handleClose}
        sx={{ zIndex: zIndex }}
        PaperProps={{
          sx: {
            marginTop: "67px",
            width: windowWidth,
            minWidth: "600px",
            maxWidth: maxWidth,
            zIndex: 21474834,
          },
          ...paperProps,
        }}
        {...drawerProps}
      >
        <Stack
          sx={{
            borderBottom: "1px solid #D8DCE2",
            position: "sticky",
            top: 0,
            zIndex: 21474834,
            background: "white",
          }}
        >
          <Stack
            justifyContent={"space-between"}
            flexDirection={"row"}
            alignItems={"center"}
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <PageSubheader>{title}</PageSubheader>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack flexDirection={"column"}>
          <Stack
            flexDirection={"column"}
            sx={{ padding: "24px", marginBottom: "67px" }}
          >
            {children}
          </Stack>
        </Stack>
        {footer && (
          <footer
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              paddingRight: "45px",
              paddingLeft: "0px",
              opacity: "0px",
              marginTop: "35px",
              boxShadow: "0px -5px 10px 0px #0000000D",
              position: "fixed",
              bottom: 0,
              background: "#fff",
              width: windowWidth,
            }}
          >
            <Stack
              sx={{
                position: "sticky",
                minHeight: "60px",
                zIndex: 9999999999,
                background: "white",
                minWidth: "100%",
                maxWidth: "100%",
              }}
            >
              {footer}
            </Stack>
          </footer>
        )}
      </Drawer>
    </React.Fragment>
  );
}
