import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";


import "dotenv/config";

import { saveUser } from "./slices/UserSlice";
import { login } from "./slices/TokensSlice";
import LoginPage from "./pages/Login/LoginPage";
import { TOKENS, USER } from "./constants/Constants";
import Theme from "./Theme";

import NavigationRoutes from "./NavigationRoutes";

const App = () => {
  const user = useSelector((state) => state.user.value);
  const tokens = useSelector((state) => state.tokens.value);
  const dispatch = useDispatch();

  let userData = localStorage.getItem(USER);
  if (!user && userData !== "undefined") {
    if (userData) {
      dispatch(saveUser(JSON.parse(userData)));
    }
  }

  let tokensData = localStorage.getItem(TOKENS);
  if (!tokens && tokensData !== "undefined") {
    if (tokensData) {
      dispatch(login(JSON.parse(tokensData)));
    }
  }

  if (!user) {
    return (
      <div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        <LoginPage />
      </div>
    );
  }

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        style={{ marginTop: "50px" }}
      />
        <NavigationRoutes />
      </Router>
    </ThemeProvider>
  );
};

export default App;
