import React, { useEffect, useState } from "react";
import { Button, Chip, TextField, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { apiCall } from "../../../api";
import SectionTopBar from "../../../components/SectionTopBar";
import Table from "../../../components/Table";
import { PageHeader } from "../../../components/Typography";
import OptionMenu from "../../../components/OptionMenu";
import UrlsSidebar from "./UrlsSidebar";
import usePostRequest from "../../../hooks/usePostRequest";
import TabularLoadingPlaceholder from "../../../components/LoadingPlaceholders/TabularLoadingPlaceholder";
import {
  generateTempId,
  getStatusChipProps,
  removeDuplicatesFromArray,
  yearsListSince,
} from "../../../utils";
import { SEARCH_STYLE } from "../../../constants/Constants";
import BottomActionBar from "../../../components/BottomActionBar";

// Colour Codes for table columns
const ERROR_COLOR = "#ffcccc"; // When required field is not provided
const CHANGED_COLOR = "#f0f8ff"; // To show the updated fields
const INITIAL_COLOR = "initial";

const RED_DOT_STYLE = {
  height: "8px",
  width: "8px",
  backgroundColor: "#FF0000",
  borderRadius: "50%",
  display: "inline-block",
  marginLeft: "5px",
};

export default function Websites() {
  const user = useSelector((state) => state.user.value);
  const tokens = useSelector((state) => state?.tokens.value);
  const params = useParams();
  const [appId, setAppId] = useState(0);
  const [websites, setWebsites] = useState([]);
  const [saved, setSaved] = useState(0);
  const [changes, setChanges] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);

  const { sendPostRequest, loading: updatingStatus } = usePostRequest(
    `/v1/apps/${currentApp?.id}/websites/`,
    tokens
  );

  // State to manage UrlsSidebar visibility and selected website
  const [isUrlsSidebarOpen, setIsUrlsSidebarOpen] = useState(false);
  const [selectedWebsiteForUrlsSidebar, setSelectedWebsiteForUrlsSidebar] =
    useState(null);

  const handleAddLinkClick = () => {
    const unfinished = websites.some((website) => website.url === "");
    if (!unfinished) {
      let newWebsites = [...websites];

      newWebsites.unshift({
        tempId: generateTempId(),
        url: "",
        urls_count: "",
        site_map: "",
        status: "",
        enabled: "enabled",
        tags: [],
        user: user,
        app_id: appId,
      });

      setWebsites(newWebsites);
    } else {
      // Highlight the unfinished row or show a notification
    }
  };

  const handleChange = (id, key, value) => {
    setWebsites((prev) =>
      prev.map((row, i) => {
        if (id === row.id || id === row.tempId) {
          return { ...row, [key]: value };
        }
        return row;
      })
    );

    setChanges((prevChanges) => ({
      ...prevChanges,
      [id]: { ...(prevChanges[id] || {}), [key]: true },
    }));

    // Clear validation error when user corrects the input
    setValidationErrors((prev) => ({
      ...prev,
      [id]: { ...(prev[id] || {}), [key]: false },
    }));
  };

  // Check if required fields are present before making the request
  // and highlighting those on the UI
  const validateRows = () => {
    let hasErrors = false;
    const errors = {};

    websites.forEach((website, index) => {
      const errorFields = {};
      if (!website.url) {
        errorFields.url = true;
        hasErrors = true;
      }
      if (!website.site_map) {
        errorFields.site_map = true;
        hasErrors = true;
      }
      if (Object.keys(errorFields).length > 0) {
        errors[index] = errorFields;
      }
    });

    setValidationErrors(errors);
    return hasErrors;
  };

  // Called on SAVE button click
  const handleSave = async () => {
    if (validateRows()) {
      return;
    }

    const websitesToSave = websites
      .filter((website, index) => changes[website.id || website.tempId])
      .map((website) => ({
        ...website,
        app_id: currentApp.id,
      }));

    try {
      await apiCall(`/v1/apps/${currentApp.id}/websites/create_and_update`, {
        method: "POST",
        body: { websites: websitesToSave },
        authTokens: tokens,
      });

      setChanges({});
      setSaved((prev) => prev + 1);
    } catch (error) {
      console.error("Error saving websites:", error);
      //TODO: Show Error Alert once component is ready
    }
  };

  useEffect(() => {
    // TODO: Move this to useFetch
    const getLinks = async () => {
      try {
        let linksList = await apiCall(
          `/v1/apps/${currentApp.id}/websites?app_name=` + params.app_name,
          {
            method: "get",
            authTokens: tokens,
          }
        );
        setWebsites(linksList);
        if (linksList.length > 0) {
          setAppId(linksList[0].app_id);
        }
        setDataLoaded(true);
      } catch (error) {
        console.error("Error fetching websites:", error);
      }
    };

    if (tokens && currentApp?.id) {
      getLinks();
    }
  }, [saved, tokens, currentApp?.id, params.app_name]);

  let tags = removeDuplicatesFromArray([
    "Development",
    "Staged",
    "Production",
    ...websites.map((w) => w.tags).flat(),
    ...(currentApp?.trigger_points || []),
  ]);

  let skipPatterns = removeDuplicatesFromArray([
    "calendar",
    "news",
    "blog",
    "contact",
    ...yearsListSince("2000"), // Add years since 2000
    ...websites.map((w) => w.skip_patterns).flat(),
  ]);

  return (
    <>
      <PageHeader>Knowledge Pool: Websites</PageHeader>
      <SectionTopBar
        style={{ justifyContent: "space-between", marginTop: "30px" }}
      >
        <TextField placeholder="Search" margin="dense" sx={SEARCH_STYLE} />
        <Button
          variant="PrimaryButtonBlue"
          onClick={handleAddLinkClick}
          // Disable button until data is loaded
          disabled={!dataLoaded}
        >
          + Add Website
        </Button>
      </SectionTopBar>

      {!dataLoaded || updatingStatus ? (
        <TabularLoadingPlaceholder numRows={9} numCols={8} />
      ) : (
        <Table
          customTableStyle={{
            width: "100%",
            marginTop: "30px",
          }}
          customStyle={{
            marginRight: "40px",
            overflowX: "visible",
          }}
          emptyText="Add URLs that your bot will refer to as its knowledge base"
          tableContents={websites?.map((website, websiteIndex) => {
            const changeSet = changes[websiteIndex] || {};
            const errorSet = validationErrors[websiteIndex] || {};
            const id = website?.id || website?.tempId;
            const showSitemapDiff = website.sitemap_diff;

            return [
              {
                style: {
                  width: "300px !important",
                  // prettier-ignore
                  backgroundColor: errorSet.url
                    ? ERROR_COLOR
                    : changeSet.url
                      ? CHANGED_COLOR
                      : INITIAL_COLOR,
                },
                id: id + "-url",
                element: {
                  type: "textfield",
                  value: website.url,
                  onChange: (e) => handleChange(id, "url", e.target.value),
                  label: "website-url",
                  placeholder: "Enter Url",
                },
              },
              {
                id: id + "-urls_count",
                style: {
                  backgroundColor: changeSet.urls_count
                    ? CHANGED_COLOR
                    : INITIAL_COLOR,
                },
                element: {
                  value: website.urls_count?.toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  }),
                },
              },
              {
                id: id + "-sitemap",
                style: {
                  // prettier-ignore
                  backgroundColor: errorSet.site_map
                    ? ERROR_COLOR
                    : changeSet.site_map
                      ? CHANGED_COLOR
                      : INITIAL_COLOR,
                },
                element: {
                  type: "selector",
                  items: [
                    {
                      title: "Full Website",
                      value: "full_website",
                    },
                    {
                      title: "Few Urls",
                      value: "few_urls",
                    },
                  ],
                  value: website.site_map,
                  setValue: (val) => handleChange(id, "site_map", val),
                },
              },
              {
                id: id + "-status",
                style: {
                  // prettier-ignore
                  backgroundColor: errorSet.status
                    ? ERROR_COLOR
                    : changeSet.status
                      ? CHANGED_COLOR
                      : INITIAL_COLOR,
                },
                element: {
                  value: (
                    <>
                      <Chip
                        label={getStatusChipProps(website.status).label}
                        color={getStatusChipProps(website.status).color}
                        variant="filled"
                        size="small"
                      />
                      {showSitemapDiff && (
                        <Tooltip title="Sitemap differences detected">
                          <span
                            style={RED_DOT_STYLE}
                            aria-label="Sitemap differences detected"
                          />
                        </Tooltip>
                      )}
                    </>
                  ),
                },
              },
              {
                id: id + "-tags",
                style: {
                  backgroundColor: changeSet.tags
                    ? CHANGED_COLOR
                    : INITIAL_COLOR,
                },
                element: {
                  type: "tags",
                  options: [...tags],
                  value: website.tags || [],
                  saveButtonClick: (newTags) => {
                    handleChange(id, "tags", newTags);
                  },
                  cancelButtonClick: () => {},
                },
              },
              {
                id: id + "-skip-patterns",
                style: {
                  backgroundColor: changeSet.tags
                    ? CHANGED_COLOR
                    : INITIAL_COLOR,
                },
                element: {
                  type: "tags",
                  title: "Add Skip Patterns",
                  infoText: "Start typing to search for and add skip patterns",
                  label: "Add Skip Patterns +",
                  options: [...skipPatterns],
                  value: website.skip_patterns || [],
                  saveButtonClick: (newSkipPatterns) => {
                    handleChange(id, "skip_patterns", newSkipPatterns);
                  },
                  cancelButtonClick: () => {},
                },
              },
              {
                id: id + "-user",
                style: {
                  backgroundColor: changeSet.user
                    ? CHANGED_COLOR
                    : INITIAL_COLOR,
                },
                element: { value: website.user.name },
              },
              {
                id: id + "-options",
                element: {
                  value: (
                    <OptionMenu
                      key={id + "-options-button"}
                      options={[
                        {
                          value: "view-links",
                          title: "View Links",
                          onClick: () => {
                            setSelectedWebsiteForUrlsSidebar(website);
                            setIsUrlsSidebarOpen(true);
                          },
                        },
                        {
                          value: "index-all",
                          title: "Index All",
                          onClick: () => {
                            sendPostRequest(
                              { status: "to_be_indexed" },
                              `${website.id}/urls/update_status`
                            );
                          },
                        },
                        {
                          value: "skip-all",
                          title: "Skip All",
                          onClick: () => {
                            sendPostRequest(
                              { status: "to_be_skipped" },
                              `${website.id}/urls/update_status`
                            );
                          },
                        },
                        {
                          value: "delete",
                          title: "Delete URLs",
                          onClick: () => {
                            sendPostRequest(
                              { status: "to_be_deleted" },
                              `${website.id}/urls/update_status`
                            );
                          },
                        },
                        {
                          value: "refresh_sitemap",
                          title: "Refresh Sitemap",
                          onClick: () => {
                            sendPostRequest(
                              { id: website.id },
                              `/sitemap_refresh`
                            );
                          },
                        },
                      ]}
                    />
                  ),
                },
              },
            ];
          })}
          rowHeaders={[
            {
              columnData: "Websites",
              id: "websites",
            },
            {
              columnData: "Urls",
              id: "urls",
            },
            {
              columnData: "Scan Mode",
              id: "scan-mode",
            },
            {
              columnData: "Status",
              id: "status",
            },
            {
              columnData: "Data Tags",
              id: "data-tags",
            },
            {
              columnData: "Skip Patterns",
              id: "skip-patterns",
            },
            {
              columnData: "Added By",
              id: "added-by",
            },
            {
              columnData: "",
              id: "options-menu",
            },
          ]}
        />
      )}

      {/* Render UrlsSidebar when open */}
      {isUrlsSidebarOpen && (
        <UrlsSidebar
          website={selectedWebsiteForUrlsSidebar}
          currentApp={currentApp}
          tokens={tokens}
          isOpen={isUrlsSidebarOpen}
          onClose={() => {
            setIsUrlsSidebarOpen(false);
            setSelectedWebsiteForUrlsSidebar(null);
          }}
        />
      )}

      {/* Sticky Footer */}
      <BottomActionBar
        disabledSave={!Object.keys(changes).some((index) => changes[index])}
        handleSave={() => {
          handleSave();
        }}
      />
    </>
  );
}
