import React, { useEffect, useRef, useState } from "react";
import { PageHeader, PageSubheader } from "../../components/Typography";
import { useSelector } from "react-redux";
import usePostRequest from "../../hooks/usePostRequest";
import useFetch from "../../hooks/useFetch";
import useValidation from "../../hooks/useValidate";
import TabularLoadingPlaceholder from "../../components/LoadingPlaceholders/TabularLoadingPlaceholder";
import SectionTopBar from "../../components/SectionTopBar";
import { Button, Checkbox } from "@mui/material";
import Table from "../../components/Table";
import BottomActionBar from "../../components/BottomActionBar";
import DataModelingTableSidebar from "./DataModelingTableSidebar";
import { isEmpty } from "lodash";
import TextFieldsLoadingPlaceholder from "../../components/LoadingPlaceholders/TextFieldsLoadingPlaceholder";

export default function DataModeling() {
  const currentUser = useSelector((state) => state.user.value);
  const tokens = useSelector((state) => state?.tokens?.value);
  const isSaveDisabled = useRef(true);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const [disabledSave, setDisabledSave] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userProfileFields, setUserProfileFields] = useState({});
  const [programProfileFields, setProgramProfileFields] = useState({});
  const { sendPostRequest, loading: updatingUserProfileStructure } =
    usePostRequest(
      `/v1/apps/${currentApp.id}/personalisation_config_details/create_and_update`,
      tokens
    );

  const {
    sendPostRequest: importConfigRequest,
    loading: loadingImportConfigRequest,
  } = usePostRequest(
    `/v1/apps/${currentApp.id}/personalisation_config_details/import_config`,
    tokens
  );

  const { data, loading, error } = useFetch(
    `/v1/apps/${currentApp.id}/personalisation_config_details`,
    tokens,
    [],
    []
  );

  const [userProfileStructures, setUserProfileStructures] = useState([]);
  const { validate, clearErrors: clearValidationErrors } = useValidation();

  useEffect(() => {
    if (data) {
      setUserProfileStructures(data);
    }
  }, [data]);

  const handleChange = (index, key, value) => {
    isSaveDisabled.current = false;
    setUserProfileStructures((prev) =>
      prev.map((row, i) => (i === index ? { ...row, [key]: value } : row))
    );
  };

  const addNewStructure = () => {
    setUserProfileStructures((prev) => {
      let newContent = [
        {
          name: "",
          definition: "",
          field_type: "",
          is_user_profile_description: false,
          is_program_detail: false,
        },
        ...prev,
      ];
      return newContent;
    });
  };

  const handleSave = async () => {
    clearValidationErrors(userProfileStructures);

    const updatedUserProfileStructure = [...userProfileStructures];

    for (let index = 0; index < updatedUserProfileStructure.length; index++) {
      const isValid = validate({
        name: updatedUserProfileStructure[index].name,
        field_type: updatedUserProfileStructure[index].field_type,
      });
      if (!isValid) {
        updatedUserProfileStructure[index] = {
          ...updatedUserProfileStructure[index],
          error: true,
        };
        setUserProfileStructures(updatedUserProfileStructure);
      }
    }
    if (updatedUserProfileStructure.some((el) => el.error)) return;

    const response = await sendPostRequest({
      personalisation_config_details: userProfileStructures,
    });
    isSaveDisabled.current = true;
  };

  const handleImportConfig = async () => {
    const response = await importConfigRequest({
      import_app_code: "eduenroll",
    });

    if (response?.status === "success" && response?.configs?.length > 0) {
      setUserProfileStructures(response?.configs);
    }
  };

  useEffect(() => {
    if (isSaveDisabled.current) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }

    let newUserProfileFields = userProfileStructures
      .filter((el) => el?.is_user_profile_description)
      ?.reduce((acc, curr) => {
        return { ...acc, [curr.name]: curr.definition };
      }, {});

    let newProgramProfileFields = userProfileStructures
      .filter((el) => el?.is_program_detail)
      ?.reduce((acc, curr) => {
        return { ...acc, [curr.name]: curr.definition };
      }, {});

    setUserProfileFields(newUserProfileFields);

    setProgramProfileFields(newProgramProfileFields);
  }, [userProfileStructures, isSaveDisabled]);

  if (loading || loadingImportConfigRequest) {
    return <TextFieldsLoadingPlaceholder numSections={2} />;
  }

  return (
    <>
      <div className="full-width flex-justify-content-space-between flex-align-items-center">
        <PageHeader>Data Model</PageHeader>
        <div className="flex-justify-content-end">
          <Button
            variant="PrimaryButton"
            sx={{
              marginRight: "5px",
            }}
            onClick={() => {
              handleImportConfig();
            }}
          >
            Import
          </Button>
          <Button
            variant="PrimaryButtonBlue"
            onClick={() => {
              setIsSidebarOpen(true);
            }}
          >
            Add/Edit
          </Button>
        </div>
      </div>

      {isSidebarOpen && (
        <DataModelingTableSidebar
          userProfileStructures={userProfileStructures}
          loading={updatingUserProfileStructure}
          handleChange={handleChange}
          currentApp={currentApp}
          tokens={tokens}
          isOpen={isSidebarOpen}
          handleSave={handleSave}
          disabledSave={disabledSave}
          onClose={() => {
            setIsSidebarOpen(false);
          }}
          addNewStructure={addNewStructure}
        />
      )}

      {!isEmpty(userProfileFields) && (
        <>
          <PageSubheader>User Profile Structure</PageSubheader>
          <pre className="personalisation-field-box">
            {JSON.stringify(userProfileFields, null, 2)}
          </pre>
        </>
      )}

      {!isEmpty(programProfileFields) && (
        <>
          <PageSubheader>Program Profile Structure</PageSubheader>

          <pre className="personalisation-field-box">
            {JSON.stringify(programProfileFields, null, 2)}
          </pre>
        </>
      )}
    </>
  );
}
