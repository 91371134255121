import React, { useState, useEffect, useCallback, useRef } from "react";
import lodashDebounce from "lodash/debounce";
import CustomTextField from "./CustomTextField";
import { LabelM } from "./Typography";

const ColorPicker = ({ label, name, value, onChange, onBlur }) => {
  const [internalValue, setInternalValue] = useState(value);
  const colorPickerOpen = useRef(false);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  // Debounce onBlur call to ensure latest value
  const debouncedOnBlur = useCallback(
    lodashDebounce((latestValue) => {
      if (onBlur) {
        onBlur({
          target: {
            name,
            value: latestValue,
          },
        });
      }
    }, 300),
    [onBlur, name]
  );

  const handleColorChange = (event) => {
    const newValue = event.target.value;
    setInternalValue(newValue);
    onChange({
      target: {
        name,
        value: newValue,
      },
    });

    // If color picker is not open, trigger debounced onBlur
    if (!colorPickerOpen.current) {
      debouncedOnBlur(newValue);
    }
  };

  const handleTextChange = (event) => {
    const newValue = event.target.value;
    setInternalValue(newValue);
    onChange({
      target: {
        name,
        value: newValue,
      },
    });

    // Trigger the debounced onBlur event with the latest value
    debouncedOnBlur(newValue);
  };

  const handleColorPickerOpen = () => {
    colorPickerOpen.current = true;
  };

  const handleColorPickerClose = () => {
    colorPickerOpen.current = false;
    debouncedOnBlur(internalValue);
  };

  return (
    <div className="form-field">
      <LabelM style={{ fontSize: "1rem", textAlign: "left", maxWidth: "40%" }}>
        {label}
      </LabelM>
      <div className="flex-row flex-align-items-center">
        <CustomTextField
          value={internalValue}
          onChange={handleTextChange}
          onBlur={() => debouncedOnBlur(internalValue)}
        />
        <div style={{ position: "relative", display: "inline-block" }}>
          <div
            style={{
              width: 24,
              height: 24,
              backgroundColor: internalValue,
              border: "1px solid black",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          />
          <input
            type="color"
            value={internalValue}
            onChange={handleColorChange}
            onFocus={handleColorPickerOpen}
            onBlur={handleColorPickerClose}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: 24,
              height: 24,
              opacity: 0,
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
