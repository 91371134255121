/* eslint-disable no-undef */

import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveApps, selectApp } from "./slices/AppsSlice";
import { apiCall } from "./api";
import { COLORS } from "./constants/Colors";
import {
  MenuExpand,
  MenuShrink,
  NavigationIcons,
} from "./components/utils/InsyncIconsUtil";
import { MENU_ITEMS_ADMIN, MENU_ITEMS_APP } from "./constants/Constants";

const getRedirectUrl = (app, url_part) => {
  if (app) {
    return (
      (process.env.REACT_APP_CURRENT_ENVIRONMENT === "production"
        ? `https://${app.url_code}-${app.country}.backoffice.shopinsync.com`
        : process.env.REACT_APP_DEV_BACKOFFICE_URL) + url_part + `?market=${app.market}`
    );
  }
  return "/";
};

export default function Navbar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const tokens = useSelector((state) => state?.tokens.value);
  const [open, setOpen] = useState({});
  const apps = useSelector((state) => state?.apps.list || []);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const [isApp, setIsApp] = useState(false);

  useEffect(() => {
    if (apps.length === 0) {
      const fetchApps = async () => {
        let appsList = await apiCall("/v1/apps?status=active", {
          method: "get",
          authTokens: tokens,
        });
        if (appsList) {
          dispatch(saveApps(appsList));
        }
      };
      fetchApps();
    }
  }, [apps.length, dispatch]);

  const currentPathSegments = location.pathname.split("/");
  const currentAppNameFromUrl = currentPathSegments[1];

  useEffect(() => {
    let selectedApp = apps.find(
      (app) => app.url_code === currentAppNameFromUrl
    );
    if (currentAppNameFromUrl && selectedApp) {
      if (!currentApp) {
        dispatch(selectApp(selectedApp));
      }
      setIsApp(true);
    } else {
      setIsApp(false);
    }
  }, [apps, currentAppNameFromUrl]);

  const menuItems = isApp ? MENU_ITEMS_APP : MENU_ITEMS_ADMIN;

  useEffect(() => {
    menuItems.forEach((item) => {
      if (item.children) {
        item.children.forEach((subItem) => {
          const resolvedPath = isApp
            ? `/${currentAppNameFromUrl}${subItem.path}`
            : `${subItem.path}`;
          if (location.pathname === resolvedPath) {
            setOpen((prevOpen) => ({
              ...prevOpen,
              [item.title]: true,
            }));
          }
        });
      }
    });
  }, [location.pathname, menuItems, currentAppNameFromUrl, isApp]);

  const handleClick = (title, path) => {
    const resolvedPath = isApp ? `/${currentAppNameFromUrl}${path}` : path;

    if (location.pathname === resolvedPath) {
      // Force page reload if clicking on the current page
      window.location.reload();
    } else {
      setOpen((prevOpen) => ({
        ...prevOpen,
        [title]: !prevOpen[title],
      }));
    }
  };

  const isSelected = (path) => {
    const resolvedPath = isApp ? `/${currentAppNameFromUrl}${path}` : path;
    return (
      location.pathname === resolvedPath ||
      location.pathname.startsWith(resolvedPath + "/")
    );
  };

  const DrawerList = (
    <Box sx={{ width: "230px" }} role="presentation">
      <List component="nav">
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {item.children ? (
              <>
                <ListItemButton
                  onClick={() => handleClick(item.title, item.path)}
                  // sx={{
                  //   bgcolor: open[item.title] ? COLORS.navBgOpen : "inherit",
                  //   border: open[item.title]
                  //     ? `1px solid ${COLORS.border}`
                  //     : "none",
                  //   ":hover": {
                  //     bgcolor: COLORS.navBgHover,
                  //   },
                  //   mb: open[item.title] ? 0 : 1.5,
                  // }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                      pl: 1,
                    }}
                  >
                    <NavigationIcons icon={item.icon} />
                    <ListItemText
                      sx={{
                        paddingLeft: "10px",
                        paddingRight: "2px",
                        fontWeight: "normal",
                      }}
                      primary={item.title}
                    />
                  </Box>
                  {open[item.title] ? <MenuExpand /> : <MenuShrink />}
                </ListItemButton>
                <Collapse in={open[item.title]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.children.map((subItem, subIndex) => (
                      <ListItemButton
                        disableRipple
                        key={subIndex}
                        sx={{
                          // pl: 8,
                          // pb: 0,
                          // pt: 0,
                          padding: 0,
                          paddingLeft: "56px",
                          mb: subIndex === item.children.length - 1 ? 1.5 : 1,
                          bgcolor: isSelected(subItem.path)
                            ? COLORS.navSubItemActive
                            : "inherit",
                        }}
                        selected={isSelected(subItem.path)}
                        component={subItem.redirect ? "a" : Link}
                        href={
                          subItem.redirect
                            ? getRedirectUrl(currentApp, subItem.path)
                            : undefined
                        }
                        target={subItem.redirect ? "_blank" : undefined}
                        to={
                          !subItem.redirect
                            ? isApp
                              ? `/${currentAppNameFromUrl}${subItem.path}`
                              : subItem.path
                            : undefined
                        }
                        onClick={() => handleClick(subItem.title, subItem.path)}
                      >
                        <ListItemText
                          sx={{
                            fontWeight: isSelected(subItem.path)
                              ? "bold"
                              : "normal",
                          }}
                          primary={subItem.title}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItemButton
                component={item.redirect ? "a" : Link}
                href={
                  item.redirect
                    ? getRedirectUrl(currentApp, item.path)
                    : undefined
                }
                target={item.redirect ? "_blank" : undefined}
                to={
                  !item.redirect
                    ? isApp
                      ? `/${currentAppNameFromUrl}${item.path}`
                      : item.path
                    : undefined
                }
                key={index}
                selected={
                  isSelected(item.alternatePath) || isSelected(item.path)
                }
                onClick={() => handleClick(item.title, item.path)}
                sx={{
                  mb: index === menuItems.length - 1 ? 0 : 1.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    pl: 1,
                  }}
                >
                  <NavigationIcons icon={item.icon} />
                  <ListItemText
                    sx={{ paddingLeft: "10px" }}
                    primary={item.title}
                  />
                </Box>
              </ListItemButton>
            )}
          </React.Fragment>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <Drawer
        ModalProps={{
          keepMounted: false,
        }}
        variant="permanent"
        PaperProps={{
          sx: {
            bgcolor: COLORS.navBg,
            marginTop: "67px",
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
