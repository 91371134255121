import React, { useState } from "react";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../../api";
import { LabelM, PageHeader, SubtitleM } from "../../components/Typography";
import { getSubdomainAndMainDomain } from "../../utils";
import FileUpload from "../../components/FileUpload/FileUpload";
import { CLIENT_TYPES, COUNTRIES, MODE_ENVS } from "../../constants/Constants";
import SwitchButton from "../../components/SwitchButton";
import BottomActionBar from "../../components/BottomActionBar";
import { selectApp } from "../../slices/AppsSlice";


const LABELM_STYLE_EXT = { fontSize: "1rem", textAlign: "left" };

export default function ManageClient(props) {
  const tokens = useSelector((state) => state?.tokens.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);
  const dispatch = useDispatch();
  const initialState = {
    client_name: "",
    primary_website: "",
    logo_url: "",
    country: "",
    market: "",
    project_url: "",
    notes: "",
    url_code: "",
    copy_market: "",
    onboard_existing_client: true,
    trigger_points: [],
    mode_env: "staging",
  };
  const [appData, setAppData] = useState(
    props.edit ? currentApp : initialState
  );

  const handleChange = (e, key) => {
    let value = key === "logo_url" ? e.target.value[0] : e.target.value;
    let newObj = { ...appData, [key]: value };

    if (key === "primary_website") {
      newObj.url_code = getSubdomainAndMainDomain(value);
    }
    setAppData((prev) => ({ ...prev, ...newObj }));
  };

  const handleLogoChange = (e) => {
    handleChange(e, "logo_url");
  };

  const handleSave = async (appData) => {
    const requestData = {
      client_name: appData.client_name,
      primary_website: appData.primary_website,
      notes: appData.notes,
      url_code: appData.url_code,
      project_url: appData.project_url,
      market: `${appData.country.toLowerCase()}_${appData.url_code}`,
      logo_url: appData.logo_url,
      country: appData.country,
      copy_market: appData.copy_market,
      onboard_existing_client: appData.onboard_existing_client,
      trigger_points: appData.trigger_points,
      mode_env: appData.mode_env,
    };

    let url = "/v1/apps" + (props.edit ? `/${currentApp.id}` : "");
    let appResponse = await apiCall(url, {
      method: props.edit ? "put" : "post",
      authTokens: tokens,
      body: requestData,
    });

    if (appResponse) {
      if (!props.edit) {
        window.location.reload();
        window.location.href = `/${appData.url_code}/knowledge/websites`;
      } else {
        dispatch(selectApp(appData));
      }
    }
  };

  // function to handle the switch toggle.
  const handleSwitchChange = (event) => {
    setAppData((prev) => ({
      ...prev,
      onboard_existing_client: event.target.checked,
    }));
  };

  //  const changeFields = (e, key) => {

  //   return

  return (
    <>
      <PageHeader>
        {props.edit ? `Welcome ${appData.client_name}` : "OnBoard Client"}
      </PageHeader>

      <div
        style={{
          alignSelf: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!props.edit && (
          <SubtitleM>
            Just need a few details of the client to start building the chat
            bot.
          </SubtitleM>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 1,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <LabelM style={LABELM_STYLE_EXT}>Client Name</LabelM>
          <TextField
            margin="dense"
            onChange={(e) => handleChange(e, "client_name")}
            value={appData.client_name}
            aria-label="Client Name"
            placeholder="Enter Company Name"
            sx={{ marginBottom: "8px" }}
          />
          <LabelM style={LABELM_STYLE_EXT}>Country/Region</LabelM>
          <Autocomplete
            margin="dense"
            options={COUNTRIES}
            onChange={(_e, val) => {
              let obj = { target: { value: val?.value || "" } };
              handleChange(obj, "country");
            }}
            disabled={props.edit}
            inputValue={appData.country}
            sx={{ width: "250px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                sx={{
                  width: "250px",
                  // marginBottom: "8px",
                }}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
            aria-label="Country"
          />
          <LabelM style={LABELM_STYLE_EXT}>{"Client's Primary Website"}</LabelM>
          <TextField
            margin="dense"
            onChange={(e) => handleChange(e, "primary_website")}
            value={appData.primary_website}
            aria-label="Client's Primary Website"
            sx={{ marginBottom: "8px" }}
            placeholder="Enter Website URL"
          />
          <LabelM style={LABELM_STYLE_EXT}>{"Client's Code"}</LabelM>
          <TextField
            margin="dense"
            disabled={props.edit}
            onChange={(e) => handleChange(e, "url_code")}
            value={appData.url_code}
            aria-label="Client's Code"
            placeholder="Client Code"
            sx={{ marginBottom: "8px" }}
          />
          <LabelM style={LABELM_STYLE_EXT}>{"Client's PROD App ID"}</LabelM>
          <TextField
            margin="dense"
            disabled
            value={appData.insync_prod_app_id}
            aria-label="Client's PROD App ID"
            sx={{ marginBottom: "8px" }}
            placeholder="Client's PROD App ID"
          />
          <LabelM style={LABELM_STYLE_EXT}>{"Client's Logo"}</LabelM>
          <div className="flex-row" style={{ marginBottom: "8px" }}>
            {appData.logo_url && (
              <img
                height={"36.5px"}
                // width={"36.5px"}
                src={appData.logo_url}
                style={{ marginRight: "6px" }}
              />
            )}
            <FileUpload
              field={{
                name: "fileUpload",
                title: appData.logo_url ? "Change" : "+ Upload",
                multiple: false,
              }}
              accept=".svg,.img,.png"
              endpoint={"/v1/apps/logo_upload"}
              onChange={handleLogoChange}
            />
          </div>
          <LabelM style={LABELM_STYLE_EXT}>Trigger Points</LabelM>
          <Autocomplete
            multiple
            freeSolo
            options={appData.trigger_points}
            value={appData.trigger_points}
            onChange={(_e, val) => {
              console.log(appData);
              let obj = { target: { value: val || "" } };
              handleChange(obj, "trigger_points");
            }}
            filterSelectedOptions
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Add trigger points"
                sx={{
                  // marginBottom: "8px",
                  "& .MuiOutlinedInput-root": {
                    paddingRight: 0,
                    paddingLeft: 0,
                  },
                }}
              />
            )}
          />
          {appData.trigger_points && appData.trigger_points.length > 0 && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                maxHeight: "150px",
                overflowY: "auto",
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              {appData.trigger_points.map((option, index) => (
                <Chip
                  key={index}
                  label={option}
                  onDelete={(_e) => {
                    let obj = {
                      target: {
                        value: appData.trigger_points?.filter(
                          (el) => el !== option
                        ),
                      },
                    };
                    handleChange(obj, "trigger_points");
                  }}
                />
              ))}
            </Box>
          )}
          <LabelM style={LABELM_STYLE_EXT}>Mode</LabelM>
          <Autocomplete
            margin="dense"
            options={MODE_ENVS}
            value={
              MODE_ENVS.find((el) => el.value === appData.mode_env)?.label ||
              "Sandbox"
            }
            sx={{ width: "250px" }}
            onChange={(e, val) => {
              let obj = { target: { value: val?.value || "" } };
              handleChange(obj, "mode_env");
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                sx={{
                  // marginBottom: "8px",
                  width: "250px",
                }}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
            aria-label="Mode"
          />
          {!props.edit && (
            <>
              <LabelM style={LABELM_STYLE_EXT}>Client Type</LabelM>
              <Autocomplete
                margin="dense"
                options={CLIENT_TYPES}
                getOptionLabel={(option) => option.title}
                sx={{ width: "250px" }}
                onChange={(e, val) => {
                  let obj = { target: { value: val?.value || "" } };
                  handleChange(obj, "copy_market");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    sx={{
                      // marginBottom: "8px",
                      width: "250px",
                    }}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
                aria-label="Country"
              />
            </>
          )}
          <LabelM style={LABELM_STYLE_EXT}>Project Url</LabelM>
          <TextField
            type="text"
            margin="dense"
            onChange={(e) => handleChange(e, "project_url")}
            value={appData.project_url}
            aria-label="project url"
            placeholder="Enter Project Url"
            sx={{ marginBottom: "8px" }}
          />
          <LabelM style={LABELM_STYLE_EXT}>Notes</LabelM>
          <TextField
            type="text"
            margin="dense"
            value={appData.notes}
            onChange={(e) => handleChange(e, "notes")}
            sx={{
              marginBottom: "8px",
              "& .MuiOutlinedInput-root": {},
              "& .MuiInputBase-input": {
                padding: "25px 20px 25px 20px",
                maxHeight: "100px",
              },
            }}
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Enter Notes"
            aria-label="Market"
          />
          {!props.edit && (
            <>
              <LabelM style={LABELM_STYLE_EXT}>Onboard Existing Client</LabelM>
              <SwitchButton
                handleChange={handleSwitchChange}
                checked={appData.onboard_existing_client}
                sx={{
                  width: 50,
                  height: 28,
                  margin: "8px",
                  "& .MuiSwitch-thumb": {
                    width: 25,
                    height: 25,
                    borderRadius: 16,
                  },
                  "& .MuiSwitch-track": {
                    borderRadius: 16,
                  },
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    transform: "translateX(22px)",
                  },
                }}
              />
            </>
          )}
        </div>
      </div>
      <BottomActionBar
        name={props.edit ? "Save" : "Create"}
        handleSave={() => {
          handleSave(appData);
        }}
        cancelRedirectUrl={props.edit ? "" : "/admin"}
      />
    </>
  );
}
