import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import TextFieldsLoadingPlaceholder from "../../components/LoadingPlaceholders/TextFieldsLoadingPlaceholder";
import CodeSnippetViewer from "../../components/CodeSnippetViewer";
import Dropdown from "../../components/Dropdown";
import {
  PageHeader,
  PageSubheader,
  SubtitleM,
  SubtitleS,
} from "../../components/Typography";

export default function BotTesting() {
  const [environment, setEnvironment] = useState("stage");

  const dropdownOptions = [
    { value: "stage", label: "Sandbox" },
    { value: "prod", label: "Production" },
  ];

  const tokens = useSelector((state) => state?.tokens?.value);
  const currentApp = useSelector((state) => state?.apps?.selectedApp);

  const { data, loading, error } = useFetch(
    `/v1/bot_installs?app_id=${currentApp?.id}&env=${environment}&app_name=${currentApp?.market}`,
    tokens,
    [environment]
  );

  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    if (data && data.content) {
      setHtmlContent(data.content);
    }
  }, [data]);

  if (loading) {
    return <TextFieldsLoadingPlaceholder numSections={1} />;
  }

  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PageHeader>Bot Testing</PageHeader>
        <Dropdown
          label="Environment"
          options={dropdownOptions}
          value={environment}
          onChange={(e) => setEnvironment(e.target.value)}
          style={{ width: "200px" }}
        />
      </Box>

      <SubtitleM>Chatbot Script</SubtitleM>
      <SubtitleS
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        WebJS to copy into your website page where the chatbot should appear.
      </SubtitleS>

      <Box sx={{ position: "relative" }}>
        <CodeSnippetViewer code={htmlContent} label="" language="javascript" />
        <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
          <Button
            component="a"
            href="https://docs.google.com/document/d/1DVDUMUchwn9IcJFgwknshGGbrBaJtKJE13g1XC1yByY/edit#heading=h.biu3vyx27s2l"
            target="_blank"
            rel="noopener noreferrer"
          >
            SCRIPT GUIDE
          </Button>
        </Box>
      </Box>
    </>
  );
}
